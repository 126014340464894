import qs from 'query-string';
import {Coordinates} from './types';

const key = 'AIzaSyAodfgf7_RN2VuHw5eOp-HgT4LRSR9sTjo';
const url = 'https://maps.googleapis.com/maps/api/staticmap';
const maptype = 'roadmap';
const size = '900x300';
const zoom = '17';

export function parseCoords(coordinates: Coordinates) {
  const latitude = Number(coordinates.lat.replace('N', ''));
  const longitude = Number(coordinates.lng.replace('W', '-'));
  return `${latitude},${longitude}`;
}

export function getStaticMapURL({lat, lng}: {lat: number; lng: number}) {
  const query = qs.stringifyUrl({
    url,
    query: {
      center: `${lat},${lng}`,
      size,
      zoom,
      maptype,
      key,
      markers: `color:blue|label:D|${lat},${lng}`
    }
  });
  return query;
}
