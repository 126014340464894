import {stylesheet} from 'typestyle';

const style = stylesheet({
  td: {
    border: "1px solid darkgray",
    padding: "3px"
  },
  btnStatusRed: {
    cursor: "pointer",
    background: "red",
    borderRadius: "5px",
    padding: "2px",
    fontSize: "0.8em",
    fontWeight: "bold",
    color: "white"
  },
  btnStatusGreen: {
    background: "green",
    borderRadius: "5px",
    padding: "2px",
    fontSize: "0.8em",
    fontWeight: "bold",
    color: "white"
  },
  modalAlertGrid: {
    display:"grid",
    gridTemplateColumns: "repeat(2)",
    gridGap: "8px",
    marginTop: "10px"
  },
  modalAlertDropdown: {
    gridColumn: "1/span 2"
  },
  modalAlertOtro: {
    gridColumn: "1/span 2"
  },
  modalAlertBtnCont: {
    textAlign: "center",
  },
  modalAlertBtn: {
    marginTop: "0.3em",
    marginRight: "0.3em",
    border: 0,
    textAlign: "center",
    fontSize: "0.8em",
    fontWeight: "bold",
    letterSpacing: "0.1em",
    cursor: "pointer",
    background: "rgba(0, 0, 0, 0.05)",
    padding: "0 0.5em",
    borderRadius: 4,
    color: "#555",
    height: "2.4em",
  }
});

export default style;
