import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from './styles'

interface Props {
  isVisible: boolean,
}

const LoadingBlockScreen = ({ isVisible }: Props) => {
  return isVisible && 
    <div className={ styles.container }>
      <div className={ styles.innerContainer }>
        <div className={ styles.spinner }>
          <FontAwesomeIcon icon={ faSpinner } spin />
        </div>
      </div>
    </div>
}

export default LoadingBlockScreen