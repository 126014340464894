import React, {useCallback, useEffect, useState} from 'react';
import {getLocations, LocationLog} from '../../services/location';
import MapStatic from '../MapStatic';

interface Props {
  deviceId?: string;
}

const MapLoader = ({deviceId}: Props) => {
  const [log, setLog] = useState<LocationLog | null>(null);

  const load = useCallback(async () => {
    try {
      if (deviceId) {
        const data = await getLocations({deviceId, limit: 1});
        setLog(data.length ? data[0] : null);
      }
    } catch (err) {
      console.log(err);
    }
  }, [deviceId]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <MapStatic
      coordinates={log ? {lat: log?.lat, lng: log?.lng} : {lat: 0, lng: 0}}
    />
  );
};

export default MapLoader;
