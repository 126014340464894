import React, { useEffect, useState } from 'react';
import styles from './styles';
import Layout from '../../components/Layout';
import { BackButton } from '../../components/BackButton';
import PagerContainer from '../../components/PagerContainer';
import { useNavigate } from 'react-router-dom';
import { ProfilesDashboard, profilesListDashboard } from '../../services/profiles';
import InputSelect from '../../components/Input/InputSelect';
import InputText from '../../components/Input/InputText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { Roles } from '../../utils/roles';

const DashboardAdmin = () => {
  const [ loading, setLoading ] = useState(false)
  const [ page, setPage ] = useState(1)
  const [ totalItems, setTotalItems ] = useState(0)
  const [ items, setItems ] = useState<ProfilesDashboard[]>([])
  const [ filterTipoUsu, setFilterTipoUsu ] = useState("")
  const [ filterSearchName, setFilterSearchName ] = useState("")
  const [ doSearch, setDoSearch ] = useState(0)
  const navigate = useNavigate()
  const perPage = 10

  useEffect(() => {
    setLoading(true)
    
    const filter = { tipoUsu: filterTipoUsu, searchName: filterSearchName }
    profilesListDashboard(page, perPage, filter)
      .then(resp => {
        setItems(resp.data)
        setTotalItems(resp.total)
      })
      .catch(err => {
        setItems([])
        setTotalItems(0)
        console.error(err)
      })
      .finally(() => setLoading(false))

    // eslint-disable-next-line
  }, [ page, doSearch ])

  return (
    <Layout background="#fcfcfc">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <BackButton />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.list}>
            <center><h3 className={styles.mainTitle}>Dashboard Administrador</h3></center>
            <center><h3 className={styles.title}>Listado de clientes</h3></center>
            <div className={styles.searchContainer}>
              <div>
                <span>Tipo clientes</span>
                <InputSelect
                  id="tipoUsuario"
                  value={filterTipoUsu}
                  options={[
                    { value: "", label: "Todos"},
                    { value: Roles.CLIENTE, label: "Cliente"},
                    { value: Roles.EMPRESA, label: "Empresa"},
                  ]}
                  onChange={(opt) => setFilterTipoUsu(opt)}
                  />
              </div>
              <div>
                <span>Buscar cliente</span>
                <InputText
                  id='search'
                  value={filterSearchName}
                  onChange={(e) => setFilterSearchName(e.target.value)}
                  />
              </div>
              <div>
              { loading ? 
                  <FontAwesomeIcon icon={faSpinner} spin />
                : <button className={styles.searchBtn} onClick={() => setDoSearch(doSearch+1)}>Buscar</button>
              }
              </div>
            </div>
            <PagerContainer
              currentPage={page}
              perPage={perPage}
              setPage={setPage}
              totalItems={totalItems}
            >
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th className={styles.tableTh}>Cliente</th>
                    <th className={styles.tableTh}>IMEI (Alertas / SOS sin atender)</th>
                  </tr>
                </thead>
                <tbody>
                  { loading
                    ? <tr><td colSpan={2} className={styles.td}>Cargando datos...</td></tr>
                    : (
                      !items.length
                        ? <tr><td colSpan={2} className={styles.td}>No se encontraron registros</td></tr>
                        : items.map(i => (
                          <tr key={i._id}>
                            <td className={styles.td}>
                              <span className={styles.link} onClick={() => navigate(`/admin/dashboard-cliente/${i._id}`)}>
                                {i.firstName} {i.firstSurname} ({ i.devices.length } dispositivos)
                              </span>
                            </td>
                            <td className={styles.td}>
                              { i.devices.map(d => <p key={d.id}>
                                {d.name || d.imei} ({ d.alertsPending } / { d.sosPending })
                              </p>)}
                            </td>
                          </tr>
                        ))
                    )
                  }
                </tbody>
              </table>
            </PagerContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DashboardAdmin;
