import React, {useCallback, useState} from 'react';
import InputText from '../Input/InputText';
import styles from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {registerDevice} from '../../services/devices';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {fetchDevices} from '../../reducers/devices';

interface Props {
  onSuccess: () => void;
}

const DeviceForm = ({onSuccess}: Props) => {
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [emp, setEmp] = useState('');
  const [id, setId] = useState('');
  const dispatch = useDispatch();

  const create = useCallback(async () => {
    try {
      if (!name || !code) {
        throw new Error('Porfavor completa todos los campos');
      }

      setStatus('creating');
      const id = await registerDevice({
        code,
        name,
        ...(emp && { codEnt: emp })
      });
      setId(id);
      setStatus('created');
      dispatch(fetchDevices() as any);
    } catch (err: any) {
      setStatus('error');

      if (err.code === 'device-not-found') {
        return setMessage('IMEI no encontrado');
      }

      if (err.code === 'device-already-registered') {
        return setMessage(
          'El dispositivo ya se encuentra registrado por otro usuario'
        );
      }

      setMessage(err.message || 'Un error inesperado ha ocurrido');
    }
  }, [name, code, emp, dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {status === 'created' ? (
          <div className={styles.registerSuccess}>
            <div className={styles.registerSuccessTitle}>
              Dispositivo registrado con exito
            </div>
            <Link
              className={styles.registerSuccessButton}
              to={'/configuracion/' + id}>
              Continuar
            </Link>
          </div>
        ) : (
          <>
            <div className={styles.header}>
              <span>Agrega un dispositivo</span>
            </div>
            <p>
              Para empezar a usar Tagyp registra un dispositivo en tu cuenta
            </p>

            <div className={styles.inputs}>
              <InputText
                id=""
                label="Nombre del dispositivo"
                onChange={(e) => {
                  setStatus('');
                  setMessage('');
                  setName(e.target.value);
                }}
              />
              <InputText
                id=""
                label="IMEI"
                onChange={(e) => {
                  setStatus('');
                  setMessage('');
                  setCode(e.target.value);
                }}
              />
              <InputText
                id=""
                label="Código de la empresa"
                onChange={(e) => {
                  setStatus('');
                  setMessage('');
                  setEmp(e.target.value);
                }}
              />
            </div>

            <div className={styles.buttons}>
              <div
                className={styles.message}
                style={{color: status === 'error' ? 'red' : 'green'}}>
                {message}
              </div>
              <button className={styles.button} onClick={create}>
                {status === 'creating' ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <span>Guardar</span>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeviceForm;
