import React, {useEffect, useState} from 'react';
import Select from 'react-select'
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../reducers';
import {profilesList} from '../../reducers/profiles';
import styles from './styles';
import Layout from '../../components/Layout';
import { BackButton } from '../../components/BackButton';
import Pager from '../../components/Pager';
import { Roles, getRoleLabel, hasRoleEmpresa, rolesForSelOpt } from '../../utils/roles';
import { setRoles } from '../../services/profiles';
import InputText from '../../components/Input/InputText';
import InputSelect from '../../components/Input/InputSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

interface Opts {
  label: string,
  value: string,
}

const AdminProfilesList = () => {
  const profiles = useSelector((state: RootState) => state.profiles);
  const [ page, setPage ] = useState(1);
  const [ editing, setEditing ] = useState(0);
  const [ roleToSet, setRoleToSet ] = useState<Opts[]>([]);
  const [ codeToSet, setCodeToSet ] = useState("");
  const [ errMsg, setErrMsg ] = useState("");
  const [ roleUpdated, setRoleUpdated ] = useState(0);
  const [ filterRol, setFilterRol ] = useState<Roles | "">("");
  const [ filterSearch, setFilterSearch ] = useState("");
  const [ isSearching, setIsSearching ] = useState(false);
  const [ doSearch, setDoSearch ] = useState(0);
  const perPage = 5;
  const dispatch = useDispatch();

  useEffect(() => {
    setIsSearching(true);
    dispatch(profilesList({ page, search: filterSearch, role: filterRol }) as any)
      .then(() => setIsSearching(false));
    // eslint-disable-next-line
  }, [dispatch, page, doSearch, roleUpdated]);

  const startEditing = (idx: number, values: Opts[], code: string = "") => {
    setEditing(idx+1)
    setRoleToSet(values)
    setCodeToSet(code)
    setErrMsg("")
  }

  const clearEditing = () => {
    setEditing(0)
    setRoleToSet([])
    setCodeToSet("")
    setErrMsg("")
  }
  
  const updateRole = (id: string) => {
    const roles = roleToSet.map(r => r.value)

    if (hasRoleEmpresa(roles) && !codeToSet) {
      setErrMsg("Debe indicar el código de la empresa")
      return
    }

    setRoles(id, roles, codeToSet).then(() => {
      setRoleUpdated(roleUpdated+1)
      setEditing(0)
    }).catch(err => setErrMsg(err.message))
  }

  return (
    <Layout background="#fcfcfc">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <BackButton />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.list}>
            <center><h3>Usuarios / Roles</h3></center>
            
            <div className={styles.searchContainer}>
              <div>
                <span>Rol</span>
                <InputSelect
                  id="rol"
                  value={filterRol}
                  options={[
                    { value: "", label: "Todos"},
                    ...(rolesForSelOpt)
                  ]}
                  onChange={opt => setFilterRol(opt as Roles | "")}
                  />
              </div>
              <div>
                <span>Nombre y apellido o email</span>
                <InputText
                  id='filterSearch'
                  value={filterSearch}
                  onChange={(e) => setFilterSearch(e.target.value)}
                  />
              </div>
              <div>
              { isSearching ? 
                  <FontAwesomeIcon icon={faSpinner} spin />
                : <button className={styles.searchBtn} onClick={() => setDoSearch(doSearch+1)}>Buscar</button>
              }
              </div>
            </div>
            
            {
              profiles.status === "loaded" &&
              <p>
                Mostrando resultados { profiles.data.length ? ((page-1) * perPage)+1 : 0} a {((page-1) * perPage) + profiles.data.length} de {profiles.total}
              </p>
            }
            {profiles.status === "loaded" && profiles.data.map((p, pIdx) => {
              const defaultValue = p.roles?.map(r => { return { value: r, label: getRoleLabel(r) }}) || []

              return (
                <div className={styles.profile} key={p.id}>
                  <div className={styles.profileDataSection}>
                    <div className={styles.prop}>Nombre: {p.firstName}</div>
                    <div className={styles.prop}>Apellido: {p.firstSurname}</div>
                    <div className={styles.prop}>Email: {p.email}</div>
                  </div>
                  <div>
                    <div className={styles.prop} style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                      <div>
                        Roles: { editing !== pIdx+1 && p.roles?.map(r => getRoleLabel(r)).join(", ")}
                        {
                          editing === pIdx+1 ?
                            <div>
                              <span>
                                <Select
                                  id={`sel-role-${p.id}`}
                                  isMulti={true}
                                  options={rolesForSelOpt}
                                  defaultValue={defaultValue}
                                  onChange={(opts) => setRoleToSet(opts as Opts[])}
                                  />
                                <button className={styles.rolesButton} onClick={(e) => updateRole(p.id)}>
                                  Guardar
                                </button>
                                <button className={styles.rolesButton} onClick={() => clearEditing()}>Cancelar</button>
                              </span>
                              { errMsg && <span style={{color:"red"}}><br/>{ errMsg }</span> }
                            </div>
                            : <div style={{display:"inline", marginLeft: "5px"}}>
                                <button className={styles.rolesButton} onClick={() => startEditing(pIdx, defaultValue, p.code)}>Editar</button>
                              </div>
                        }
                        </div>
                        <div style={{padding:"0 5px"}}>
                          { editing === pIdx+1
                            && roleToSet.map(r => r.value).includes(Roles.EMPRESA)
                            && <div>
                              Código de la empresa: <InputText id={`inp-code-${p.id}`}
                                value={codeToSet}
                                onChange={e => setCodeToSet(e.target.value)}
                                />
                            </div>
                          }
                        </div>
                    </div>
                  </div>
                </div>
              )
            })}
          <div>
            <center>
              { profiles.status === "loaded" && <Pager currentPage={page} totalPages={profiles.total/perPage} setPage={setPage} />}
            </center>
          </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminProfilesList;
