import moment from 'moment';
import React from 'react';
import {HeartRateLog} from '../../../services/heartRate';
import styles from './styles.module.css';

interface Props {
  data: HeartRateLog[];
}

const ReportsManualTable = ({data}: Props) => {
  return (
    <div className={styles.boundsTable}>
      <div className={styles.title}>
        <span>Mediciones a demanda del usuario</span>
      </div>
      <div className={styles.listHeader} style={{gridTemplateColumns: "repeat(6, 1fr)"}}>
        <div>Fecha</div>
        <div>Sistólica</div>
        <div>Diastólica</div>
        <div>Frecuencia</div>
        <div>Oxigenación</div>
        <div>Condición</div>
      </div>
      <div className={styles.body}>
        {data.map((value, i) => (
          <div key={i} className={styles.listRow} style={{gridTemplateColumns: "repeat(6, 1fr)"}}>
            <div>{moment(value.date).format('YYYY-MM-DD HH:mm')}</div>
            <div>{value.high}</div>
            <div>{value.low}</div>
            <div>{value.rhythm}</div>
            <div>{value.oxygen || "-"}</div>
            <div>{value.status}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportsManualTable;
