import {stylesheet} from 'typestyle';

const style = stylesheet({
  button: {
    marginTop: "0.3em",
    border: 0,
    textAlign: "center",
    fontSize: "1em",
    fontWeight: "bold",
    letterSpacing: "0.1em",
    cursor: "pointer",
    background: "rgba(0, 0, 0, 0.15)",
    padding: "0 0.5em",
    borderRadius: 4,
    color: "#555",
    height: "2.4em",
  },
  buttonActive: {
    marginTop: "0.3em",
    border: 0,
    textAlign: "center",
    fontSize: "1em",
    fontWeight: "bold",
    letterSpacing: "0.1em",
    background: "rgba(0, 0, 0, 0.05)",
    padding: "0 0.5em",
    borderRadius: 4,
    color: "#555",
    height: "2.4em",
  }
});

export default style;
