import React from 'react';
import {useServiceWorker} from '../../utils/useServiceWorker';
import styles from './styles.module.css';

const UpdateSW: React.FC = () => {
  const data = useServiceWorker();

  if (!data.isUpdateAvailable) {
    return null;
  }

  return (
    <div className={styles.container}>
      Hay una nueva versión de la aplicación
      <button className={styles.button} onClick={data.updateAssets}>
        Actualizar
      </button>
    </div>
  );
};

export default UpdateSW;
