import React, { useState } from 'react';
import {removeDevice} from '../../services/devices';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import styles from './styles';

interface RemoveDeviceProps {
  onRemoveSuccess: () => void;
  id: string;
}

const RemoveDevice = ({ id, onRemoveSuccess }: RemoveDeviceProps) => {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const save = async () => {
    try {
      setStatus('loading');
      setMessage('');
      await removeDevice(id);
      setStatus('loaded');
      onRemoveSuccess();
    } catch (error: any) {
      setStatus('error');
      setMessage(error.message || 'Ocurrio un error inesperado');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <span>Eliminar dispositivo</span>
      </div>
      <div className={styles.buttons}>
        <div className={styles.message}>{message}</div>
        <button className={styles.buttonRed} onClick={save}>
          {status === 'saving' || status === 'loading' ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <span>Eliminar</span>
          )}
        </button>
      </div>
    </div>
  );
}

export default RemoveDevice;