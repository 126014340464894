import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    width: '100%',
    height: '100%'
  },
  wrapper: {
    maxWidth: 900,
    margin: '0 auto'
  },
  list: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflowY: "scroll",
    padding: "2em",
    background: "#FFF",
    boxShadow: "1px 1px 5px 1px rgba(0,0,0,.05)",
    borderRadius: 4,
  },
  table: {
    border:"1px solid darkgray",
    borderCollapse: "collapse",
  },
  tableTh: {
    width: "50%",
    padding: "3px",
    color: "gray",
  },
  td: {
    border: "1px solid darkgray",
    padding: "3px"
  },
  delete: {
    color: "red",
    cursor: "pointer",
    marginLeft: "3px"
  },
  spanFilter: {
    verticalAlign: "middle",
  },
  searchContainer: {
    display: "grid",
    columnGap: "5px",
    gridTemplateColumns: "100px 50% 100px"
  },
  searchBtn: {
    background: 'rgba(0, 0, 0, .04)',
    color: '#555',
    fontWeight: 600,
    padding: '1em',
    borderRadius: 4,
    cursor: "pointer",
    verticalAlign: "top",
  }
});

export default style;
