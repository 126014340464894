import React from 'react'
import styles from './styles'
import { RouteProps } from 'react-router-dom';

type PropsDataTable = RouteProps & {
  title?: string,
  headers: { label: string }[],
  caption?: string,
}

const DataTable = ({
  children,
  title,
  headers,
  caption,
}: PropsDataTable) => {
  return (
    <div className={styles.container}>
      { title && <h3 className={styles.title}>{title}</h3> }
      { caption && <span className={styles.caption}>{caption}</span>}
      <table className={styles.table}>
        <thead>
          <tr>
            { headers.map(h => {
              return <th className={styles.tableTh} key={h.label}>
                {h.label}
              </th>
            })}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default DataTable;