import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    width: '100%',
    height: '100%'
  },
  wrapper: {
    maxWidth: 900,
    margin: '0 auto'
  }
});

export default style;
