import React from 'react';
import {getStaticMapURL} from '../../utils/location';
import styles from './styles';

interface Props {
  coordinates?: {lat: number; lng: number};
}

const MapStatic = ({coordinates}: Props) => (
  <div className={styles.container}>
    <img
      className={styles.image}
      src={coordinates ? getStaticMapURL(coordinates) : ''}
      alt=""
    />
  </div>
);

export default MapStatic;
