import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Modal from 'react-modal';

Sentry.init({
  dsn:
    'https://3346c1832f194e8495cf0f21e483ed52@o95465.ingest.sentry.io/5622250',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

const container = document.getElementById('root');
Modal.setAppElement('#root');
const root = createRoot(container!);
root.render(
  <App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
