import React, { useState } from 'react';
import { auth as firebaseAuth } from '../../utils/firebase';
import Header from '../Header';
import styles from './styles';
import {ConnectedProps, connect} from 'react-redux';
import {RootState} from '../../reducers';
import {setCurrentDevice} from '../../reducers/devices';
import {bindActionCreators} from '@reduxjs/toolkit';
import { withRouter } from '../../utils/withRouter';
import { RouteProps, useNavigate } from 'react-router-dom';

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & RouteProps & {background?: string};

const connector = connect(
  (state: RootState) => ({
    devices: state.devices,
    auth: state.auth
  }),
  (dispatch) => bindActionCreators({setCurrentDevice}, dispatch)
);

const Layout = (props: Props) => {
    const {children, auth, background, devices, setCurrentDevice} = props;
    const [usermenuOpen, setUsermenuOpen] = useState(false);
    const [adminMenuOpen, setAdminMenuOpen] = useState(false);
    const navigate = useNavigate();

    const signOut = async () => {
        await firebaseAuth.signOut();
        navigate('/auth/login');
    };

    const closeMenu = () => {
        setUsermenuOpen(false);
        if (adminMenuOpen) setAdminMenuOpen(false);
    };

    const toggleMenu = () => {
        setUsermenuOpen(!usermenuOpen);
        if (adminMenuOpen) setAdminMenuOpen(false);
    };

    return (
        <div className={styles.containerLayout} onClick={closeMenu}>
            <Header
                user={auth.user}
                admin={auth.user?.level ? auth.user.level > 0 : false}
                onLogout={signOut}
                usermenuOpen={usermenuOpen}
                onUserButtonClick={toggleMenu}
                adminMenuOpen={adminMenuOpen}
                setAdminMenuOpen={setAdminMenuOpen}
                current={devices.current}
                devices={devices.devices}
                setCurrentDevice={(device) => setCurrentDevice(device.id)}
            />
            <div
            className={styles.content}
            style={{background: background || '#FFF'}}>
            {children}
            </div>
        </div>
    );
}

export default withRouter(connector(Layout));
