import React, { useState } from 'react'
import Modal from 'react-modal'
import styles from './styles'
import InputSelect from '../../Input/InputSelect';
import InputText from '../../Input/InputText';
// import { setSosChecked } from '../../../services/heartRate';
import { healthCheckedStatusOpt } from '../../../utils/const';
import { setSosChecked } from '../../../services/sos';

export interface ModalSosData {
  deviceId: string,
  sosId: string,
  name?: string,
  condition?: string,
  altitud: number,
  latitud: number,
  longitud: number,
  tag?: string,
}

interface Props {
  isOpen: boolean,
  isLoading: boolean,
  data: ModalSosData,
  onClose: () => void,
  afterSave: () => void,
  setIsLoading: (val: boolean) => void,
}

const ModalSos = ({
  isOpen,
  data,
  onClose,
  afterSave,
  setIsLoading,
}: Props) => {
  const [ status, setStatus ] = useState("")
  const [ otro, setOtro ] = useState("")

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "50%"
    },
  };

  const closeModal = function() {
    setStatus("")
    setOtro("")
    onClose()
  }

  const saveModal = function() {
    if (!status || (status === "otro" && !otro?.trim())) {
      return
    }
    
    setIsLoading(true)
    return setSosChecked(data.sosId, data.deviceId, status, otro || undefined)
      .then(r => {
        setStatus("")
        setOtro("")
        afterSave()
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false))
  }

  return <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Example Modal"
  >
    <center><h3>{ data.name }</h3></center>
    <div className={ styles.modalSosGrid }>
      <div>Condición: { data.condition }</div>
      <div>Altitud: { data.altitud }</div>
      <div>Latitud: { data.latitud }</div>
      <div>Longitud: { data.longitud }</div>
      <div>Usuario tag: { data.tag }</div>
      <div className={ styles.modalSosDropdown }>
        Estatus:
        <InputSelect
          id="sosSelect"
          value={status}
          onChange={val => {
            if (otro) setOtro("")
            setStatus(val)
          }}
          options={[
            { label: "Seleccione", value: "" },
            ...healthCheckedStatusOpt
          ]}
        />
      </div>
      { status === "otro" && <div className={ styles.modalSosOtro }>
          Especifique: <InputText id="sosOtro" value={otro} onChange={e => setOtro(e.target.value)}  />
        </div>
      }
      <div className={ styles.modalSosBtnCont }>
        <button className={ styles.modalSosBtn } onClick={closeModal}>Cancelar</button>
      </div>
      <div className={ styles.modalSosBtnCont }>
        <button className={ styles.modalSosBtn } onClick={saveModal}>Guardar</button>
      </div>
    </div>
  </Modal>
}

export default ModalSos