import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    position: 'relative',
    width: '100%',
    paddingTop: '30%',
    background: '#F8F9FA',
    boxShadow: '1px 1px 5px 2px rgba(0,0,0,.08)',
    borderRadius: 4,
    overflow: 'hidden'
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover'
  }
});

export default style;
