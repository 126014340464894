import React from 'react';
import {Link, RouteProps} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/pro-regular-svg-icons';
import styles from './styles.module.css';

export const BackButton = ({path}: RouteProps) => (
  <Link to={path || '/'} className={styles.button}>
    <FontAwesomeIcon icon={faAngleLeft} />
    <span>Volver</span>
  </Link>
);
