import React, { useEffect, useState } from 'react';
import {getHeartRate, HearRateReport} from '../../services/heartRate';
import {useNavigate, useParams} from 'react-router-dom';
import {ConnectedProps, connect} from 'react-redux';
import {RootState} from '../../reducers';
import HealtReport from './HealthReport';

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const connector = connect((state: RootState) => ({
  auth: state.auth
}));

const Heart = ({ auth }: Props) => {
    const [status, setStatus] = useState("");
    const [log, setLog] = useState<HearRateReport>(null as any);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            try {
                if (id) {
                    setStatus('loading');
                    const log = await getHeartRate(id);
                    setStatus('loaded')
                    setLog(log);
                    return;
                }
                navigate('/');
            } catch (error) {
                setStatus('error')
                console.log(error);
            }
        };

        getData().catch(err => { console.log(err) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
      <HealtReport
        loading={auth.status === 'loading' || status === 'loading'}
        log={log}
        backButtonPath={`/inicio/${id}`}
      />
    );
}

export default connector(Heart);
