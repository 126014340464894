export const healthCheckedStatusOpt = [
  { label: "Canalizado", value: "canalizado" },
  { label: "Precondición de salud ", value: "precondicion" },
  { label: "Error de uso", value: "error" },
  { label: "Otros", value: "otro" },  
]

export const healthCheckedStatusText = (val: string, otro?: string) => {
  if (val === "otro") return otro

  return healthCheckedStatusOpt.find(r => r.value === val)?.label
}