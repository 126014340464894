import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getProfileDefaultState} from '../services/profiles';
import {Profile} from '../utils/types';

interface User {
  uid: string;
  email: string;
  displayName: string;
  emailVerified: boolean;
  photoURL: string;
  level: number;
}

type SliceState = {
  status: 'loading' | 'loaded' | 'error';
  user: User | null;
  profile: Profile;
};

const initialState: SliceState = {
  status: 'loading',
  user: null,
  profile: getProfileDefaultState()
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authUser(state, action: PayloadAction<User>) {
      state.status = 'loaded';
      state.user = action.payload;
    },
    signOutUser(state, action: PayloadAction) {
      state.status = 'loaded';
      state.user = null;
    }
  },
  extraReducers: (builder) => {}
});

export const {authUser, signOutUser} = authSlice.actions;

export default authSlice.reducer;
