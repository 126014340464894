import React from 'react';
import {HearRateReport} from '../../../services/heartRate';
import Graph from '../../../components/Graph';
import styles from './styles.module.css';

interface Props {
  data: HearRateReport;
  bound: 'higher' | 'lower';
}

const highColor = '#5195CE';
const lowColor = '#E24D42';

const MaxPression = ({data, bound}: Props) => {
  const dataArray = data.bounds;
  const labels = dataArray.map((a) => a[0]);
  const high = dataArray.map(
    (a) => a[1][bound === 'higher' ? 'maximumHigh' : 'minimumHigh']
  );
  const low = dataArray.map(
    (a) => a[1][bound === 'higher' ? 'maximumLow' : 'minimumLow']
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>
          {bound === 'higher'
            ? 'Presión Arterial mediciones máximas 30 Min.'
            : 'Presión Arterial mediciones mínimas 30 Min.'}
        </span>
      </div>
      <div className={styles.wrapper}>
        <Graph
          labels={labels}
          datasets={[
            {
              data: high,
              label: 'Sistólica',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderColor: highColor
            },
            {
              data: low,
              label: 'Diastólica',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderColor: lowColor
            }
          ]}
        />
      </div>
    </div>
  );
};

export default MaxPression;
