import React, { useEffect, useState } from 'react'
import Graph from '../Graph/Graph';
import DataTable from '../DataTable/DataTable';
import styles from './styles'
import { Device, Health, Profile, SOS } from '../../../utils/types';
import Pager from '../../Pager';
import LoadingBlockScreen from '../../LoadingBlockScreen';
import ModalAlert, { ModalAlertData } from '../ModalAlert/ModalAlert';
import ModalSos, { ModalSosData } from '../ModalSos/ModalSos';
import { healthCheckedStatusText } from '../../../utils/const';
import { getDeviceAlerts, getDevicesByProfile } from '../../../services/devices';
import { formatDate } from '../../../utils/time';
import Select from 'react-select';

interface Props {
  profileId: string,
  deviceId: string,
  setDeviceId: (id: string) => void,
}

type DeviceData = Device & {
  alerts: Health[],
  alertsTotal: number,
  sos: (SOS & { health?: Health })[],
  sosTotal:number,
  account: Profile,
  tagUser?: string,
}

const DeviceSel = ({ profileId, deviceId, setDeviceId }: Props) => {
  const [ devices, setDevices ] = useState<Device[]>([])
  const [ deviceData, setDeviceData ] = useState<DeviceData | null>(null)
  const [ alertPage, setAlertPage ] = useState(1)
  const [ refreshAlerts, setRefreshAlerts ] = useState(0)
  const [ sosPage, setSosPage ] = useState(1)
  const [ refreshSos, setRefreshSos ] = useState(0)
  const [ loading, setLoading ] = useState(false)
  const [ modalAlertIsOpen, setModalAlertIsOpen ] = useState(false)
  const [ modalAlertData, setModalAlertData ] = useState<ModalAlertData>()
  const [ modalSosIsOpen, setModalSosIsOpen ] = useState(false)
  const [ modalSosData, setModalSosData ] = useState<ModalSosData>()
  const alertPerPage = 10
  const sosPerPage = 10

  // Busca los dispositivos segun el perfil
  useEffect(() => {
    if (profileId) {
      getDevicesByProfile(profileId, true)
        .then((data) => {
          setDevices(data.result)
        }).catch(err => {
          console.error(err)
          setDevices([])
        })
    } else {
      setDevices([])
    }

    setDeviceData(null)
    setDeviceId("")
    setAlertPage(1)
    setSosPage(1)
  }, [ profileId, setDeviceId ])

  // Busca las alertas del dispositivo
  useEffect(() => {
    if (deviceId) {
      setLoading(true)
      getDeviceAlerts(deviceId, true, {
        alertPage,
        alertPerPage,
        sosPage,
        sosPerPage,
      }).then((data) => {
        setDeviceData(data.result)
      }).catch(err => {
        console.error(err)
        setDeviceData(null)
      }).finally(() => setLoading(false))
    } else {
      setDeviceData(null)
    }
  }, [ deviceId, alertPage, sosPage, refreshAlerts, refreshSos ])

  // Abre la modal de alertas
  const openModalAlert = function(hData: Health, deviceData: DeviceData) {
    setModalAlertIsOpen(true);
    setModalAlertData({
      deviceId: deviceData.id,
      alertId: hData._id,
      name: [deviceData.account?.firstName, deviceData.account?.firstSurname].join(" "),
      condition: deviceData.condition,
      tag: "",
      low: hData.low,
      high: hData.high,
      rhythm: hData.rhythm,
    })
  }

  // Abre la modal de sos
  const openModalSos = function(sData: SOS, deviceData: DeviceData) {
    setModalSosIsOpen(true);
    setModalSosData({
      deviceId: deviceData.id,
      sosId: sData._id,
      name: [deviceData.account?.firstName, deviceData.account?.firstSurname].join(" "),
      condition: deviceData.condition,
      tag: "",
      altitud: sData.ubication?.altitude,
      latitud: sData.ubication?.lat,
      longitud: sData.ubication?.lng,
    })
  }

  const tableAlertHeaders = [
    { label: "Nombre Apellido" },
    { label: "Cuenta" },
    { label: "Condición" },
    { label: "FC, PA" },
    { label: "Usuario TAG" },
    { label: "Fecha" },
    { label: "" },
  ]

  const tableSosHeaders = [
    { label: "Nombre Apellido" },
    { label: "Cuenta" },
    { label: "Condición" },
    { label: "Geolocalización / FC, PA" },
    { label: "Usuario TAG" },
    { label: "Fecha" },
    { label: "" },
  ]

  const alertCaption = "Mostrando resultados "
    + (!deviceData?.alertsTotal ? 0 : ((alertPage-1) * alertPerPage)+1)
    + " a " 
    + (((alertPage-1) * alertPerPage) + (deviceData?.alerts?.length || 0))
    + " de "
    + (deviceData?.alertsTotal || 0)

  const sosCaption = "Mostrando resultados "
    + (!deviceData?.sosTotal ? 0 : ((sosPage-1) * sosPerPage)+1)
    + " a " 
    + (((sosPage-1) * sosPerPage) + (deviceData?.sos?.length || 0))
    + " de "
    + (deviceData?.sosTotal || 0)

  const numDevices = devices.length
  const devicesOptions = [{value:"", label:"Seleccione un dispositivo"}, ...devices.map(d => {return {
    label: `${d.name} (${d.imei})`,
    value: d.id
  }})]

  return (
    <div>
      <LoadingBlockScreen isVisible={ loading } />
      <div>
        {
          modalAlertData && <ModalAlert
            isOpen={ modalAlertIsOpen }
            isLoading={ loading }
            data={ modalAlertData }
            onClose={ () => setModalAlertIsOpen(false) }
            afterSave={ () => {
              setModalAlertIsOpen(false)
              setRefreshAlerts(refreshAlerts+1)
            }}
            setIsLoading={ setLoading }
          />
        }
      </div>
      <div>
        {
          modalSosData && <ModalSos
            isOpen={ modalSosIsOpen }
            isLoading={ loading }
            data={ modalSosData }
            onClose={ () => setModalSosIsOpen(false) }
            afterSave={ () => {
              setModalSosIsOpen(false)
              setRefreshSos(refreshSos+1)
            }}
            setIsLoading={ setLoading }
          />
        }
      </div>
      <div>
        <span>Usuario ({ numDevices } dispositivos)</span>
        <Select
          value={devicesOptions.find(opt => opt.value === deviceId)}
          options={devicesOptions}
          onChange={opt => setDeviceId(opt?.value || "")}
          />
      </div>
      <DataTable
        title="Alertas parámetros fuera de rango"
        headers={tableAlertHeaders}
        caption={alertCaption}
      >
        { !deviceData?.alerts?.length
          ? <tr><td colSpan={6} className={styles.td}>No se encontraron resultados</td></tr>
          : deviceData?.alerts?.map(h => {
            return <tr key={h._id}>
              <td className={styles.td}>{deviceData.name}</td>
              <td className={styles.td}>{`${deviceData.account?.firstName || ""} ${deviceData.account?.firstSurname || ""}`}</td>
              <td className={styles.td}>{deviceData.condition}</td>
              <td className={styles.td}>{`${h.high}/${h.low} ${h.rhythm}`}</td>
              <td className={styles.td}><center>{deviceData.tagUser || "-"}</center></td>
              <td className={styles.td}>{formatDate(h.date)}</td>
              <td className={styles.td}>
                { h.checked ? (
                  <center>
                    <button className={styles.btnStatusGreen}>
                      { healthCheckedStatusText(h.checkedStatus || "", h.checkedOtro) }
                    </button>
                  </center>
                ) : (
                  <center>
                    <button className={styles.btnStatusRed} onClick={() => openModalAlert(h, deviceData)}>Pendiente</button>
                  </center>
                )}
              </td>
            </tr>
          })
        }
      </DataTable>
      <div>
        <center>
          <Pager currentPage={alertPage} totalPages={(deviceData?.alertsTotal || 0)/alertPerPage} setPage={setAlertPage} />
        </center>
      </div>
      <DataTable
        title="Alertas SOS"
        headers={tableSosHeaders}
        caption={sosCaption}
      >
        { !deviceData?.sos?.length
          ? <tr><td colSpan={6} className={styles.td}>No se encontraron resultados</td></tr>
          : deviceData?.sos?.map(s => {
            return <tr key={s._id}>
              <td className={styles.td}>{deviceData.name}</td>
              <td className={styles.td}>{`${deviceData.account?.firstName || ""} ${deviceData.account?.firstSurname || ""}`}</td>
              <td className={styles.td}>{deviceData.condition}</td>
              <td className={styles.td}>
                Altitud: {s.ubication.altitude}
                <br/>Latitud: {s.ubication.lat}
                <br/>Longitud: {s.ubication.lng}
                <br/>FC/PA: {s.health?.high}/{s.health?.low} {s.health?.rhythm}
              </td>
              <td className={styles.td}><center>{deviceData.tagUser || "-"}</center></td>
              <td className={styles.td}>{formatDate(s.date)}</td>
              <td className={styles.td}>
                { s.checked ? (
                  <center>
                    <button className={styles.btnStatusGreen}>
                      { healthCheckedStatusText(s.checkedStatus || "", s.checkedOtro) }
                    </button>
                  </center>
                ) : (
                  <center>
                    <button className={styles.btnStatusRed} onClick={() => openModalSos(s, deviceData)}>Pendiente</button>
                  </center>
                )}
              </td>
            </tr>
          })
        }
      </DataTable>
      <div>
        <center>
          <Pager currentPage={sosPage} totalPages={(deviceData?.sosTotal || 0)/sosPerPage} setPage={setSosPage} />
        </center>
      </div>
      <Graph
        device={deviceId}
        />
    </div>
  );
};

export default DeviceSel;