import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    width: '100%',
    height: '100%'
  },
  wrapper: {
    maxWidth: 900,
    margin: '0 auto'
  },
  rolesButton: {
    marginTop: "0.3em",
    marginRight: "0.3em",
    border: 0,
    textAlign: "center",
    fontSize: "0.7em",
    fontWeight: "bold",
    letterSpacing: "0.1em",
    cursor: "pointer",
    background: "rgba(0, 0, 0, 0.05)",
    padding: "0 0.5em",
    borderRadius: 4,
    color: "#555",
    height: "2.4em",
  },
  rolesSelect: {},
  list: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflowY: "scroll",
    padding: "2em",
    background: "#FFF",
    boxShadow: "1px 1px 5px 1px rgba(0,0,0,.05)",
    borderRadius: 4,
  },
  profile: {
    width: "100%",
    padding: "1.5em 1.5em",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    margin: "1em 0",
    boxShadow: "2px 2px 3px 1px rgba(0, 0, 0, 0.05)",
  },
  profileDataSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  prop: {
    padding: "0.5em 0",
    fontWeight: "500",
    fontSize: "1em",
  },
  profileContactSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "2em 0",
  },
  profileUbicationSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    padding: "2em 0 0 0",
  },
  searchContainer: {
    marginTop: "20px",
    display: "grid",
    columnGap: "5px",
    gridTemplateColumns: "1fr 1fr 100px"
  },
  searchBtn: {
    background: 'rgba(0, 0, 0, .04)',
    color: '#555',
    fontWeight: 600,
    padding: '1em',
    borderRadius: 4,
    cursor: "pointer",
    verticalAlign: "bottom",
    marginTop: "1em",
  }
});

export default style;
