import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import UserMenu from '../UserMenu';
import {Device} from '../../utils/types';
import DeviceSelector from '../DeviceSelector';
import logo from '../../assets/logos/tagyp1.png';
import brand from '../../assets/logos/tagyp2.png';
import styles from './styles.module.css';
import AdministracionMenu from '../AdministracionMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { Roles, hasRole } from '../../utils/roles';

interface Props {
  onLogout: () => void;
  onUserButtonClick: () => void;
  adminMenuOpen: boolean;
  setAdminMenuOpen: (open: boolean) => void;
  usermenuOpen: boolean;
  user?: {photoURL: string; displayName: string; email: string} | null;
  admin?: boolean;
  current?: Device | null;
  devices: Device[];
  setCurrentDevice: (device: Device) => void;
}

export default function Header(props: Props) {
  const {
    onLogout,
    usermenuOpen,
    onUserButtonClick,
    adminMenuOpen,
    setAdminMenuOpen,
    user,
    admin,
    current,
    devices,
    setCurrentDevice
  } = props;
  const profile = useSelector((state: RootState) => state.profile.profile);

  return (
    <div className={styles.header}>
      <div className={styles.brand}>
        <Link to="/">
          <img src={logo} className={styles.logo} alt="" />
          <img src={brand} className={styles.logoText} alt="" />
        </Link>
      </div>
      <div className={styles.buttons}>
        {
          hasRole(profile.roles, [ Roles.ADMIN, Roles.EMPRESA ]) && 
          <AdministracionMenu
            adminMenuOpen = { adminMenuOpen }
            setAdminMenuOpen = { setAdminMenuOpen }
          />
        }
        <DeviceSelector
          current={current}
          devices={devices}
          setCurrentDevice={setCurrentDevice}
        />

        <div className={styles.usermenuContainer}>
          <button
            className={styles.button}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onUserButtonClick();
              return 0;
            }}
            style={{display: user ? 'flex' : 'none'}}>
            {user && user.photoURL ? (
              <img src={user.photoURL} className={styles.userImage} alt="" />
            ) : (
              <FontAwesomeIcon icon={faUser} className={styles.icon} />
            )}
          </button>
          <UserMenu onLogout={onLogout} open={usermenuOpen} admin={admin} />
        </div>

        <Link
          to="/iniciar"
          className={styles.button}
          style={{display: user ? 'none' : 'flex'}}>
          Iniciar
        </Link>
        <Link
          to="/registro"
          className={styles.button}
          style={{display: user ? 'none' : 'flex'}}>
          Registrarse
        </Link>
      </div>
    </div>
  );
}
