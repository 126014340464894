import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import {normalize, setupPage} from 'csstips';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationTriangle,
  faPowerOff,
  faAddressBook,
  faBell,
  faPhoneAlt,
  faComments,
  faUser,
  faExclamationCircle,
  faSignOut,
  faLockAlt,
  faWatchFitness,
  faIdBadge,
  faLongArrowUp,
  faHeart,
  faSearchLocation,
  faRedoAlt,
  faTrashAlt,
  faCog
} from '@fortawesome/pro-regular-svg-icons';
import './utils/firebase';
import './utils/i18n';
import {configureStore} from '@reduxjs/toolkit';
import {Provider} from 'react-redux';
// import Auth from './components/Auth';
import Home from './containers/Home';
import Heart from './containers/Heart';
import Configurations from './containers/Configurations';
import Locations from './containers/Locations';
import {rootReducer} from './reducers';
import {getAuth} from './utils/firebase';
import {authUser, signOutUser} from './reducers/auth';
import {cleanDevices, fetchDevices} from './reducers/devices';
import AddDevice from './containers/AddDevice';
import UpdateSW from './components/UpdateSW';
import WeeklyReportParser from './containers/WeeklyReportParser';
import Boot from './containers/Boot';
import LoadingWrapper from './components/LoadingWrapper';
import {profileShow} from './reducers/profile';
// import Admin from './containers/Admin';
import Register from './containers/Register';
import Login from './containers/Login';
import Recover from './containers/Recover';
import Profile from './containers/Profile';
import AdminProfilesList from './containers/AdminProfilesList';
import Clientes from './containers/Clientes';
import DashboardCliente from './containers/DashboardCliente';
import DashboardAdmin from './containers/DashboardAdmin';
import SeleccionVistaInicio from './containers/SeleccionVistaInicio';

normalize();
setupPage('#root');

library.add(
  faExclamationTriangle,
  faPowerOff,
  faAddressBook,
  faBell,
  faPhoneAlt,
  faComments,
  faUser,
  faExclamationCircle,
  faSignOut,
  faLockAlt,
  faWatchFitness,
  faIdBadge,
  faLongArrowUp,
  faHeart,
  faSearchLocation,
  faRedoAlt,
  faTrashAlt,
  faCog
);

const store = configureStore({reducer: rootReducer});

getAuth().onAuthStateChanged(async (user) => {
  if (user) {
    const token = await user.getIdTokenResult();
    const level = token.claims.level || 0;

    store.dispatch(
      authUser({
        uid: user.uid,
        email: user.email || '',
        displayName: user.displayName || '',
        emailVerified: user.emailVerified,
        photoURL: user.photoURL || '',
        level
      })
    );
    store.dispatch(profileShow());
    store.dispatch(fetchDevices());
  } else {
    store.dispatch(signOutUser());
    store.dispatch(cleanDevices());
  }
});

function App() {
  console.log('version 1.0.10');
  return (
    <Provider store={store}>
      <UpdateSW />
      <Router>
        <Routes>
          <Route path="/registro" element={<Register />} />
          <Route path="/iniciar" element={<Login />} />
          <Route path="/recuperar-contraseña" element={<Recover />} />
          {/* <Route path="/auth" element={<Auth />} /> */}
          <Route
            path="/reports/weekly/token/:token"
            element={<WeeklyReportParser/>}
          />
          <Route path="/" element={<LoadingWrapper />}>
              <Route path="/seleccion-inicio" element={<SeleccionVistaInicio/>} />
              <Route path="/perfil/*" element={<Profile/>} />
              <Route path="/dispositivos/nuevo" element={<AddDevice/>} />
              <Route path="/salud/:id" element={<Heart/>} />
              <Route path="/mapa/:id" element={<Locations/>} />
              <Route path="/configuracion/:id" element={<Configurations/>} />
              <Route path="/ubicaciones/:id" element={<Locations/>} />
              <Route path="/inicio/:id" element={<Home/>} />
              {/* <Route path="/admin" element={<Admin/>} /> */}
              <Route path="/admin/users" element={<AdminProfilesList/>} />
              <Route path="/admin/clientes" element={<Clientes/>} />
              <Route path="/admin/dashboard-cliente/:profileId?" element={<DashboardCliente />} />
              <Route path="/admin/dashboard-admin" element={<DashboardAdmin />} />
              <Route path="/" element={<Boot/>} />
              <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
