import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as profileServices from '../services/profiles';
import {Profile} from '../utils/types';

type SliceState = {
  status: 'loading' | 'loaded' | 'error';
  data: Profile[];
  total: number;
};

const initialState: SliceState = {
  status: 'loading',
  data: [],
  total: 0,
};

export const profilesList = createAsyncThunk('profiles/list', async (params: profileServices.ProfileListParams) => {
  const response = await profileServices.profilesList({ ...params });
  return response;
});

const profileSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    setProfiles(state, action: PayloadAction<Profile[]>) {
      state.status = 'loaded';
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(profilesList.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(profilesList.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.data = action.payload.data;
      state.total = action.payload.total
    });
  }
});

export const {setProfiles} = profileSlice.actions;

export default profileSlice.reducer;
