import {combineReducers} from 'redux';
import auth from './auth';
import devices from './devices';
import profile from './profile';
import profiles from './profiles';
import adminDevices from './adminDevices';

export const rootReducer = combineReducers({
  auth,
  devices,
  adminDevices,
  profile,
  profiles
});

export type RootState = ReturnType<typeof rootReducer>;
