import get from 'lodash/get';
import fetch from '../utils/fetch';
import firebase, {getIdToken, requireUser} from '../utils/firebase';
import {dateFromTimestampSafe} from '../utils/time';

export interface UbicationLog {
  altitude: string;
  date: Date | null;
  day: string;
  dayOfYear: string;
  direction: string;
  hour: string;
  lat: string;
  lng: string;
  speed: string;
}

export interface UbicationReport {
  altitude: string;
  deviceId: string;
  deviceName: string;
  direction: string;
  endOfWeek: string;
  lat: string;
  lng: string;
  speed: string;
  startOfWeek: string;
  updated: string;
  userId: string;
  week: string;
  year: string;
  zone: string;
  logs: UbicationLog[];
}

export function parseUbicationLog(data: unknown): UbicationLog {
  return {
    date: dateFromTimestampSafe(get(data, 'date', null)),
    altitude: get(data, 'altitude', ''),
    day: get(data, 'day', ''),
    dayOfYear: get(data, 'dayOfYear', ''),
    direction: get(data, 'direction', ''),
    hour: get(data, 'hour', ''),
    lat: get(data, 'lat', ''),
    lng: get(data, 'lng', ''),
    speed: get(data, 'speed', '')
  };
}

export function parseUbicationReport(data: unknown): UbicationReport {
  return {
    altitude: get(data, 'altitude', ''),
    deviceId: get(data, 'deviceId', ''),
    deviceName: get(data, 'deviceName', ''),
    direction: get(data, 'direction', ''),
    endOfWeek: get(data, 'endOfWeek', ''),
    lat: get(data, 'lat', ''),
    lng: get(data, 'lng', ''),
    speed: get(data, 'speed', ''),
    startOfWeek: get(data, 'startOfWeek', ''),
    updated: get(data, 'updated', ''),
    userId: get(data, 'userId', ''),
    week: get(data, 'week', ''),
    year: get(data, 'year', ''),
    zone: get(data, 'zone', ''),
    logs: Object.values(get(data, 'logs', {}))
      .map(parseUbicationLog)
      .sort((a, b) => (a.date as Date).getTime() - (b.date as Date).getTime())
  };
}

export function parseLocationLogFromSnapshot(
  snapshot: firebase.firestore.DocumentSnapshot
): UbicationReport {
  return parseUbicationReport({...snapshot.data(), id: snapshot.id});
}

export async function getLocationLog(id: string): Promise<UbicationReport> {
  const user = requireUser();

  const snapshot = await firebase
    .firestore()
    .collection('ubication')
    .where('userId', '==', user.uid)
    .orderBy('startOfWeek', 'desc')
    .limit(1)
    .get();

  const data = snapshot.docs.map(parseLocationLogFromSnapshot);

  return data[0];
}

export interface LocationLog {
  id: string;
  deviceId: string;
  userId: string;
  zone: number;
  deviceName: string;
  serverTimestamp: string;
  deviceTimestamp: string;
  gps: boolean;
  lat: number;
  lng: number;
  speed: number;
  direction: number;
  altitude: number;
  satelites: number;
  gsm: number;
  power: number;
  pedometer: number;
  tumblings: number;
  status: string;
  unix: number;
  date: string;
}

function parseLog(data: unknown): LocationLog {
  return {
    id: get(data, '_id', ''),
    deviceId: get(data, 'deviceId', ''),
    userId: get(data, 'userId', ''),
    zone: get(data, 'zone', 0),
    deviceName: get(data, 'deviceName', ''),
    serverTimestamp: get(data, 'serverTimestamp', ''),
    deviceTimestamp: get(data, 'deviceTimestamp', ''),
    gps: get(data, 'gps', false),
    lat: get(data, 'lat', 0),
    lng: get(data, 'lng', 0),
    speed: get(data, 'speed', 0),
    direction: get(data, 'direction', 0),
    altitude: get(data, 'altitude', 0),
    satelites: get(data, 'satelites', 0),
    gsm: get(data, 'gsm', 0),
    power: get(data, 'power', 0),
    pedometer: get(data, 'pedometer', 0),
    tumblings: get(data, 'tumblings', 0),
    status: get(data, 'status', ''),
    unix: get(data, 'unix', 0),
    date: get(data, 'date', '')
  };
}

export interface GetLocationsParams {
  deviceId: string;
  limit?: number;
}
export async function getLocations({
  deviceId,
  limit
}: GetLocationsParams): Promise<LocationLog[]> {
  const token = await getIdToken();
  const res = await fetch('ubications/v1/list', token, {deviceId, limit});
  return res.result.map(parseLog);
}
