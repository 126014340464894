import React from "react";
import Layout from "../../components/Layout";
import styles from './styles';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faUser } from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Roles, hasRole } from "../../utils/roles";

const SeleccionVistaInicio = () => {
  const logedProfile = useSelector((state: RootState) => state.profile.profile)
  const dashboardLink = hasRole(logedProfile.roles, [Roles.ADMIN, Roles.OPERADOR]) ? '/admin/dashboard-admin' : `/admin/dashboard-cliente`

  return <Layout>
    <div className={styles.container}>
      <div className={styles.optionsContainer}>
        <Link className={styles.option} to={dashboardLink}>
          <FontAwesomeIcon icon={faTable} className={styles.icon} />
          <div className={styles.label}>Dashboard</div>
        </Link>
        <Link className={styles.option} to='/perfil'>
          <FontAwesomeIcon icon={faUser} className={styles.icon} />
          <div className={styles.label}>Perfil</div>
        </Link>
      </div>
    </div>
  </Layout>
}

export default SeleccionVistaInicio;