import React, { useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import InputText from '../../components/Input/InputText';
import styles from './styles';
import {Device} from '../../utils/types';
import {devicesUpdateSOS} from '../../services/devices';

interface SOSProps {
  id: string;
  data: Device;
}

const phoneno = /^\+?([0-9]{8,14})$/;

function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const SOSConfig = ({ id, data }: SOSProps) => {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [formError, setFormError] = useState<Record<string, string>>();
  const [sos1, setSos1] = useState(data?.sos1 || "");
  const [email1, setEmail1] = useState(data?.email1 || "");
  const [sos2, setSos2] = useState(data?.sos2 || "");
  const [email2, setEmail2] = useState(data?.email2 || "");

  const save = async () => {
    setFormError({});
    try {
      const formError: Record<string, string> = {};

      if (!phoneno.test(sos1)) {
        formError.sos1 = 'Número de teléfono inválido';
      }

      if (!phoneno.test(sos2)) {
        formError.sos2 = 'Número de teléfono inválido';
      }

      if (!validateEmail(email1)) {
        formError.email1 = 'Correo electrónico inválido';
      }

      if (!validateEmail(email2)) {
        formError.email2 = 'Correo electrónico inválido';
      }

      if (Object.values(formError).length) {
        setStatus('error');
        setMessage('Datos invalidos');
        setFormError(formError);
        return;
      }

      setStatus('saving');
      setMessage('');
      await devicesUpdateSOS({id, sos1, email1, sos2, email2});
      setStatus('saved');
      setMessage('Guardado con éxito');
    } catch (error: any) {
      console.log(error);
      setStatus('error');
      setMessage(error.message || 'Ocurrio un error inesperado');
      console.log(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span>Contactos cercanos</span>
      </div>

      <div className={styles.contact}>
        <div className={styles.contactTitle}>Contacto cercano 1:</div>
        <div className={styles.contactProperties}>
          <InputText
            id="sos1"
            label="Número de teléfono"
            value={sos1}
            onChange={(e) => setSos1(e.target.value)}
            error={formError?.['sos1']}
          />
          <InputText
            id="email1"
            label="Correo electrónico"
            value={email1}
            onChange={(e) => setEmail1(e.target.value)}
            error={formError?.['email1']}
          />
        </div>
      </div>

      <div className={styles.contact}>
        <div className={styles.contactTitle}>Contacto cercano 2:</div>
        <div className={styles.contactProperties}>
          <InputText
            id="sos2"
            label="Número de teléfono"
            value={sos2}
            onChange={(e) => setSos2(e.target.value)}
            error={formError?.['sos2']}
          />
          <InputText
            id="email2"
            label="Correo electrónico"
            value={email2}
            onChange={(e) => setEmail2(e.target.value)}
            error={formError?.['email2']}
          />
        </div>
      </div>

      <div className={styles.buttons}>
        <div className={styles.message}>{message}</div>
        <button className={styles.button} onClick={save}>
          {status === 'saving' || status === 'loading' ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <span>Guardar</span>
          )}
        </button>
      </div>
    </div>
  );
}

export default SOSConfig;