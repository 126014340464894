import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    margin: "10px 0 0",
  },
  inputContainer: {
    width: "50%",
    display: "inline-block",
    margin: "0 10px",
  },
  addSpan: {
    verticalAlign: 'top'
  },
  addBtn: {
    background: 'rgba(0, 0, 0, .04)',
    color: '#555',
    fontWeight: 600,
    padding: '1em',
    borderRadius: 4,
    cursor: "pointer",
    verticalAlign: "top"
  }
});

export default style;
