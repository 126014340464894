import {stylesheet} from 'typestyle';
import {BaseInput} from '../styles';

const styles = stylesheet({
  ...BaseInput,
  errorMessage: {
    height: '2em',
    color: 'red',
    padding: '.2em .5em'
  }
});

export default styles;
