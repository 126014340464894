import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {bindActionCreators} from '@reduxjs/toolkit';
import Layout from '../../components/Layout';
import DeviceForm from '../../components/DeviceForm';
import {RootState} from '../../reducers';
import {setCurrentDevice, fetchDevices} from '../../reducers/devices';
import {BackButton} from '../../components/BackButton';
import styles from './styles.module.css';

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const connector = connect(
  (state: RootState) => ({
    devices: state.devices,
    auth: state.auth
  }),
  (dispatch) => bindActionCreators({setCurrentDevice, fetchDevices}, dispatch)
);

const AddDevice = ({ fetchDevices }: Props) => {
    return (
        <Layout>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <BackButton />
            </div>
            <DeviceForm onSuccess={() => fetchDevices()} />
          </div>
        </Layout>
    );
}

export default connector(AddDevice);
