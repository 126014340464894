import React from 'react';
import {HearRateReport} from '../../../services/heartRate';
import Graph from '../../../components/Graph';
import styles from './styles.module.css';

interface Props {
  data: HearRateReport;
}

const maxColor = '#2F575E';
const minColor = '#65C5DB';

const Rhythm = ({data}: Props) => {
  const dataArray = data.bounds;
  const labels = dataArray.map((a) => a[0]);
  const max = dataArray.map((a) => a[1].maximumRhythm);
  const min = dataArray.map((a) => a[1].minimumLow);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>Frecuencia cardíaca, mediciones cada 30 Min.</span>
      </div>
      <div className={styles.wrapper}>
        <Graph
          labels={labels}
          datasets={[
            {
              data: max,
              label: 'Max. Frecuencia cardiaca',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderColor: maxColor
            },
            {
              data: min,
              label: 'Min. Frecuencia cardiaca',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderColor: minColor
            }
          ]}
        />
      </div>
    </div>
  );
};

export default Rhythm;
