import {stylesheet} from 'typestyle';

const style = stylesheet({
  title: {
    textAlign: "center",
    fontWeight: "normal"
  },
});

export default style;
