import firebase from 'firebase/compat';
import moment from 'moment';

export function dateFromTimestamp(
  timestamp: firebase.firestore.Timestamp
): Date {
  if (timestamp) {
    return timestamp.toDate();
  }
  return new Date();
}

export function formatDate(date: Date | null): string {
  return date ? moment(date).format('DD/MM/YYYY HH:mm') : '';
}

export function formatDateForGraph(date: Date | null): string {
  return date ? moment(date).format('HH:mm') : '';
}

export function dateFromTimestampSafe(
  timestamp?: firebase.firestore.Timestamp | null
): Date | null {
  if (timestamp && timestamp instanceof Date) {
    return timestamp;
  }
  if (timestamp && timestamp.toDate) {
    return timestamp.toDate();
  }
  return null;
}

export function timestamToString(
  timestamp?: firebase.firestore.Timestamp | null
): string {
  if (timestamp?.toDate) {
    return timestamp.seconds + '';
  }
  return '';
}
