 import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {signUpWithEmail} from '../../../services/auth';
import Text from '../../Input/InputText';
import style from './style.module.css';
import Checkbox from '../../Input/InputCheckbox';
import CustomError from '../../../utils/CustomError';

type Props = {
  loginPath: string,
  logo: string,
  onRegisterSuccess: () => void,
};

const Register = (props: Props) => {
  const [process, setProcess] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [terms, setTerms] = useState(false);
  const [formError, setFormError] = useState({
    firstName: "",
    email: "",
    password: "",
    repassword: "",
  });
  const { t } = useTranslation();
  const { loginPath, logo } = props;

  const onRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const errors: any = {
        firstName: "",
        email: "",
        password: "",
      };

      if (!firstName) errors.firstName = 'Introduce tu nombre';
      if (!email) errors.email = 'Correo electrónico inválido';
      if (!password || password !== repassword) errors.password = 'Las contraseñas no coinciden';

      if (Object.values(errors).length) {
        setProcess("");
        setStatus("failure");
        setMessage("");
      }
      
      setFormError({...errors})

      if (!terms) {
        throw new CustomError('Debes aceptar los términos y condiciones');
      }

      setProcess("register");
      setStatus("");
      setMessage("");
      await signUpWithEmail(email, password, firstName);
      setStatus("success")
      props.onRegisterSuccess();
    } catch (error: any) {
      console.log(error);
      const errors: any = {};
      if (error.code === 'auth/email-already-in-use') {
        errors.email = 'El correo electrónico ya está en uso';
      }

      if (error.code === 'auth/invalid-email') {
        errors.email = 'Correo electrónico inválido';
      }

      if (error.code === 'auth/weak-password') {
        errors.password = 'La contraseña debe tener al menos 6 caracteres';
      }

      if (Object.values(errors).length) {
        setProcess("");
        setStatus("failure");
        setFormError({...errors})
        return;
      }

      setProcess("");
      setStatus("failure");
      setMessage(error.message);  
    }
  };

  const success = (
    <div className={style.success}>
      <h2>{t('verifyYourEmail')}</h2>
      <p className={style.intructions}>{t('verifyEmailInstructions')}</p>
      <Link to="/" className={style.sendBtn}>
        {t('continue')}
      </Link>
    </div>
  );

  console.log(formError);
  const form = (
    <form className={style.form} onSubmit={onRegister}>
      <Text
        id="firstName"
        label={t('firstName')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
        value={firstName}
        inputClassname={style.input}
        error={formError.firstName}
      />

      <Text
        id="email"
        label={t('email')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        value={email}
        inputClassname={style.input}
        error={formError.email}
      />

      <Text
        id="password"
        label={t('password')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        value={password}
        inputClassname={style.input}
        password={true}
        error={formError.password}
      />

      <Text
        id="repassword"
        label={t('repassword')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRepassword(e.target.value)}
        value={repassword}
        inputClassname={style.input}
        password={true}
        error={formError.repassword}
      />

      <div className={style.termsContainer}>
        <Checkbox
          value={terms}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTerms(e.target.checked)}
          text={
            <p className={style.termsText}>
              He leído y acepto el{' '}
              <a target="_blank" href="/privacidad">
                Aviso de Privacidad
              </a>
            </p>
          }
        />
      </div>

      <div className={style.buttonContainer}>
        <button className={style.sendBtn}>
          {process ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            t('register')
          )}
        </button>
        <Link className={style.registerLink} to={{pathname: loginPath}}>
          {t('haveAccount')} <strong>{t('login')}</strong>
        </Link>
      </div>

      <div className={style.errorContainer}>{message}</div>
    </form>
  );

  return (
    <div className={style.login}>
      <div className={style.logoContainer}>
        {logo ? <img src={logo} alt="" className={style.logo} /> : null}
      </div>
      {status === 'success' ? success : form}
    </div>
  );
}

export default Register;
