import {getIdToken} from '../utils/firebase';
import fetch from '../utils/fetch';

export async function setSosChecked(sosId: string, deviceId: string, checkedStatus: string, checkedOtro?: string) {
  const token = await getIdToken();
  const res = await fetch('sos/v1/set-checked', token, {
    deviceId,
    sosId,
    checkedStatus,
    checkedOtro,
  });
  console.log("setChecked res: ", res)
}