import React from 'react';
import styles from './styles.module.css';
import logo from '../../assets/logos/logo01.png';
import Layout from '../../components/Layout';
import LoginComponent from '../../components/Auth/Login';
import { withRouter } from '../../utils/withRouter';

const Login = () => {
    return (
        <Layout>
            <div className={styles.container}>
                <LoginComponent
                    logo={logo}
                    registerPath="/registro"
                    recoverPath="/recuperar-contraseña"
                    />
            </div>
        </Layout>
    );
};

export default withRouter(Login);
