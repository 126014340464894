import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import InputText from '../Input/InputText';
import {Profile} from '../../utils/types';
import InputSelect from '../Input/InputSelect';
import styles from './styles';
import InputDate from '../Input/InputDate';
import { hasRoleEmpresa } from '../../utils/roles';

interface ProfilesFormParams {
  value: Profile;
  loading: boolean;
  onChange: (data: Profile) => void;
  onSubmit: () => void;
  message: string;
}

const ProfilesForm = ({
  value,
  loading,
  message,
  onChange,
  onSubmit
}: ProfilesFormParams) => {
  return (
    <div className={styles.container}>
      <div className={styles.rows}>
        <InputText
          id="firstName"
          label="Primer nombre"
          value={value.firstName}
          onChange={(e) => onChange({...value, firstName: e.target.value})}
        />
        <InputText
          id="firstSurname"
          label="Primer apellido"
          value={value.firstSurname}
          onChange={(e) => onChange({...value, firstSurname: e.target.value})}
        />
        <InputText
          id="secondSurname"
          label="Segundo apellido"
          value={value.secondSurname}
          onChange={(e) => onChange({...value, secondSurname: e.target.value})}
        />

        <InputDate
          id="dob"
          label="Fecha de nacimiento"
          value={value.dob ? new Date(value.dob) : new Date()}
          onChange={(dob) => onChange({...value, dob: dob.toISOString()})}
        />

        <InputSelect
          id="genre"
          label="Género"
          value={value.genre}
          onChange={(genre) => onChange({...value, genre})}
          options={[
            {value: 'female', label: 'Mujer'},
            {value: 'male', label: 'Hombre'}
          ]}
        />
        <InputSelect
          id="martialStatus"
          label="Estado civil"
          value={value.martialStatus}
          onChange={(martialStatus) => onChange({...value, martialStatus})}
          options={[
            {value: 'single', label: 'Soltero'},
            {value: 'married', label: 'Casado'},
            {value: 'other', label: 'Otro'}
          ]}
        />
      </div>
      <div className={styles.rows}>
        <InputText
          id="primaryPhone"
          label="Teléfono local"
          value={value.primaryPhone}
          onChange={(e) => onChange({...value, primaryPhone: e.target.value})}
        />
        <InputText
          id="secundaryPhone"
          label="Teléfono celular"
          value={value.secundaryPhone}
          onChange={(e) => onChange({...value, secundaryPhone: e.target.value})}
        />
      </div>

      <div className={styles.rows}>
        <InputText
          id="primaryPhone"
          label="País"
          value="Venezuela"
          onChange={(e) => onChange({...value, country: e.target.value})}
        />
        <InputText
          id="state"
          label="Estado"
          value={value.state}
          onChange={(e) => onChange({...value, state: e.target.value})}
        />
        <InputText
          id="municipality"
          label="Municipio"
          value={value.municipality}
          onChange={(e) => onChange({...value, municipality: e.target.value})}
        />
        <InputText
          id="zipCode"
          label="Código postal"
          value={value.zipCode}
          onChange={(e) => onChange({...value, zipCode: e.target.value})}
        />
        {
          hasRoleEmpresa(value.roles) &&
            <InputText
              id=""
              label="Código de empresa"
              value={ value.code }
              readOnly={true}
            />
        }
      </div>

      <InputText
        id="address"
        label="Dirección"
        value={value.address}
        onChange={(e) => onChange({...value, address: e.target.value})}
      />

      <div className={styles.buttons}>
        <div>{message}</div>
        <button className={styles.button} onClick={onSubmit}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Guardar'}
        </button>
      </div>
    </div>
  );
};

export default ProfilesForm;
