import React, {useEffect, useState} from 'react';
import styles from './styles';
import Layout from '../../components/Layout';
import { BackButton } from '../../components/BackButton';
import Pager from '../../components/Pager';
import { getIdToken } from '../../utils/firebase';
import fetch from '../../utils/fetch';
import FormEmpresaAddCliente from '../../components/FormEmpresaAddCliente';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import InputText from '../../components/Input/InputText';

interface ClienteData {
  _id: string,
  profile: {
    firstName?: string,
    firstSurname?: string,
  },
  devices: {
    name: string,
    imei: string
  }[]
}

const Clientes = () => {
  const [ page, setPage ] = useState(1);
  const [ totalClientes, setTotalClientes ] = useState(0);
  const [ dataClientes, setDataClientes ] = useState<ClienteData[]>([]);
  const [ loaded, setLoaded ] = useState(false);
  const [ token, setToken ] = useState("");
  const [ refresh, setRefresh ] = useState(0);
  const [ filterSearch, setFilterSearch ] = useState("");
  const perPage = 10;
  if (!token) getIdToken().then(t => setToken(t));

  useEffect(() => {
    setLoaded(false)
    if (token) {
      fetch('empresa/v1/get-clientes', token, {
        page,
        perPage,
        ...(filterSearch && { imei: filterSearch })
      }).then(data => {
        setDataClientes(data.result.data || [])
        setTotalClientes(data.result.total || 0)
      })
      .catch(err => { console.error(err) })
      .finally(() => setLoaded(true))
    }
    // eslint-disable-next-line
  }, [token, page, refresh])

  const doRefresh = () => setRefresh(refresh+1)

  const delImei = (imei: string) => {
    if (token && window.confirm("¿Desea eliminar este imei?")){
      fetch('empresa/v1/del-cliente', token, { imei }).then(data => {
        setRefresh(refresh+1)
      }).catch(err => { console.error(err) })
    }
  }
  
  return (
    <Layout background="#fcfcfc">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <BackButton />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.list}>
            <FormEmpresaAddCliente
              token={token}
              afterAdding={doRefresh}
              />

            <div className={styles.searchContainer}>
              <span className={styles.spanFilter}>Filtrar IMEI: </span>
              <div>
                <InputText
                  id='filterSearch'
                  value={filterSearch}
                  onChange={(e) => setFilterSearch(e.target.value)}
                  placeholder='IMEI'
                  />
              </div>
              <div>
              { !loaded ? 
                  <FontAwesomeIcon icon={faSpinner} spin />
                : <button className={styles.searchBtn} onClick={() => doRefresh()}>Buscar</button>
              }
              </div>
            </div>
            
            <center><h3>Usuarios</h3></center>

            {
              loaded &&
              <p>
                Mostrando resultados {totalClientes === 0 ? 0 : ((page-1) * perPage)+1} a {((page-1) * perPage) + (dataClientes?.length || 0)} de {totalClientes}
              </p>
            }
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.tableTh}>Nombre</th>
                  <th className={styles.tableTh}>IMEI</th>
                </tr>
              </thead>
              <tbody>
                {
                  dataClientes?.map(d => {
                    return (<tr key={d._id}>
                      <td className={styles.td}>
                        {d.profile?.firstName} {d.profile?.firstSurname}
                      </td>
                      <td className={styles.td}>
                        {d.devices.map(d => <div key={d.imei}>
                          {d.name} ({d.imei})
                          <span className={styles.delete} title='Eliminar' onClick={() => delImei(d.imei)}>
                            <FontAwesomeIcon icon={faRemove} />
                          </span>
                          <br/>
                        </div>)}
                      </td>
                    </tr>)
                  })
                }
              </tbody>
            </table>
            <div>
              <center style={{marginTop:"3px"}}>
                { loaded && <Pager currentPage={page} totalPages={totalClientes/perPage} setPage={setPage} />}
              </center>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Clientes;
