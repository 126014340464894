import React from 'react';
import styles from './styles'
import { RouteProps } from 'react-router-dom';
import Pager from '../Pager';

type PagerProps = RouteProps & {
  title?: string,
  currentPage: number,
  totalPages?: number,
  perPage: number,
  totalItems: number,
  numPagesAtSides?: number,
  setPage: any,
}

const PagerContainer = (props: PagerProps) => {
  const { title, currentPage, perPage, totalItems, numPagesAtSides=3, setPage, children } = props
  const from = ((currentPage-1) * perPage) +1
  const to = Math.min(from + perPage -1, totalItems)

  return (
    <div>
      { title && <h3 className={styles.title}>{title}</h3>}
      <p>Mostrando resultados {from} a {to} de {totalItems}</p>
      <div>{ children }</div>
      <Pager
        currentPage={currentPage}
        totalPages={Math.ceil(totalItems / perPage)}
        setPage={setPage}
        numPagesAtSides={numPagesAtSides}
        />
    </div>
  )
}

export default PagerContainer