import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import styles from './styles';
import {devicesShow} from '../../services/devices';
import {BackButton} from '../../components/BackButton';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import {Device} from '../../utils/types';
import { useNavigate, useParams } from 'react-router-dom';
import SOSConfig from './SOS';
import RemoveDevice from './Remove';
import InfoConfig from './Info';

const connector = connect((state: RootState) => ({
  devices: state.devices,
  auth: state.auth
}));

const Configurations = () => {
  const [status, setStatus] = useState("");
  const [device, setDevice] = useState<Device>();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const load = async (id: string) => {
      try {
        setStatus('loading');
        const device = await devicesShow(id);
        setStatus('loaded');
        setDevice(device);
      } catch (error: any) {
        setStatus('error');
        console.log(error);
      }
    }

    if (id) load(id);
  }, [id]);

  return (
    <Layout background="#fcfcfc">
      <div className={styles.container}>
        <div className={styles.sectionHeader}>
          <BackButton path={`/inicio/${id}`} />
        </div>

        {status === 'loaded' ? (
          <>
            <InfoConfig id={id!} data={device!} />
            <SOSConfig id={id!} data={device!} />
            <RemoveDevice
              id={id!}
              onRemoveSuccess={() => navigate('/')}
            />
          </>
        ) : null}
      </div>
    </Layout>
  );
}

export default connector(Configurations);
