import React, { useEffect, useState } from 'react'
import styles from './styles'
import Chart from 'chart.js/auto'
import moment from 'moment';
import { getDateRangeRecords } from '../../../services/devices';

interface Props {
  device: string,
}

const Graph = ({ device }: Props) => {
  const [ graph, setGraph ] = useState<Chart>()

  const fromDate = moment().startOf("week")
  const toDate = moment().endOf("week")
  const dateArr: string[] = []

  if (fromDate < toDate) {
    const currentDate = fromDate.clone()

    while (currentDate < toDate) {
      dateArr.push(currentDate.format("DD-MM-Y"))
      currentDate.add(1, "day")
    }
  }

  useEffect(() => {
    if (device) {
      graph?.destroy()

      getDateRangeRecords(device, fromDate.format("Y-MM-DD"), toDate.format("Y-MM-DD"))
      .then((resp) => {
        const data = dateArr.map(theDay => {
          let sos = 0
          let alerts = 0
          let records = 0

          for (const r of resp.result.sosDailyTotals) {
            if (r.day === theDay) {
              sos += r.total
            }
          }

          for (const r of resp.result.recordsDailyTotals) {
            if (r.day === theDay) {
              if (r.recordType === "normal") {
                records += r.total
              } else {
                alerts += r.total
              }
            }
          }

          return {
            date: theDay,
            sos,
            alerts,
            records,
          }
        })
        
        const chart = new Chart(
          document.getElementById('graph-canvas') as HTMLCanvasElement,
          {
            type: 'line',
            data: {
              labels: data.map(r => r.date),
              datasets: [{
                label: 'SOS',
                data: data.map(r => r.sos)
              }, {
                label: 'Alertas',
                data: data.map(r => r.alerts)
              }, {
                label: 'Registros normales',
                data: data.map(r => r.records)
              }]
            }
          }
        );
        setGraph(chart)
      }).catch(err => {
        console.error(err)
        setGraph(undefined)
      })
    } else {
      graph?.destroy()
      setGraph(undefined)
    }

    return () => {
      graph?.destroy()
      setGraph(undefined)
    }
    // eslint-disable-next-line
  }, [ device ])

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Alertas de la semana</h3>
      <div className={styles.graphContainer}>
        <div className={styles.graph}>
          <canvas id="graph-canvas"></canvas>
        </div>
      </div>
    </div>
  );
};

export default Graph;