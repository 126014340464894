import React from "react";
import styles from "./styles";

export interface Change {
  id: string;
  value: string | number | string[] | boolean | undefined | Date;
}
interface Props {
  id?: string;
  value?: boolean;
  label?: string;
  text?: string | React.JSX.Element;
  ref?: React.Ref<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputCheckbox = (props: Props) => {
  const { id, value, label, text, onChange } = props;
  return (
    <div className={styles.container}>
      <div className={styles.labelContainer}>
        {label ? (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        ) : null}
      </div>
      <div className={styles.wrapper}>
        <input
          type="checkbox"
          id={id}
          className={styles.input}
          checked={value}
          onChange={onChange}
        />
        <label htmlFor={id} className={styles.text}>
          { text }
        </label>
      </div>
    </div>
  );
};

export default InputCheckbox;
