import React, { useEffect, useState } from 'react'
import styles from './styles'
import { getDevicesAlertsCount } from '../../../services/profiles';

interface Props {
  profileId: string,
  token: string,
  setDeviceId: (imei: string) => void,
}

interface DeviceData {
  id: string,
  imei: string,
  name?: string,
  numAlerts: number,
  numAlertsDone: number,
}

const DeviceTable = ({ profileId, setDeviceId }: Props) => {
  const [ loading, setLoading ] = useState(false)
  const [ data, setData ] = useState<DeviceData[]>([])
  
  useEffect(() => {
    if (profileId) {
      setLoading(true)
      getDevicesAlertsCount(profileId)
        .then((data) => { setData(data.result) })
        .catch(err => { console.error(err) })
        .finally(() => setLoading(false))
    }
  }, [ profileId ])
  
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Alertas por usuario</h3>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.tableTh}>
              Usuario
            </th>
            <th className={styles.tableTh}>
              Total Alertas
            </th>
            <th className={styles.tableTh}>
              Total Atendidas
            </th>
          </tr>
        </thead>
        <tbody>
          { loading
            ? <tr><td colSpan={3} className={styles.td}>Cargando datos...</td></tr>
            : (
              !data.length
              ? <tr><td colSpan={3} className={styles.td}>No se encontraron registros</td></tr>
              : data.map(r => <tr key={r.name}>
                <td className={styles.td}>
                  <span className={styles.clickable} onClick={() => setDeviceId(r.id)}>{ r.name || r.imei }</span>
                </td>
                <td className={styles.tdCenter}>{r.numAlerts}</td>
                <td className={styles.tdCenter}>{r.numAlertsDone}</td>
              </tr>)
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default DeviceTable;