import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    width: '100%',
    marginTop: "15px",
  },
  table: {
    width: "100%",
    border:"1px solid darkgray",
    borderCollapse: "collapse",
    marginTop: "5px",
  },
  tableTh: {
    padding: "3px",
    color: "gray",
  },
  title: {
    textAlign: "center",
    fontWeight: "normal"
  },
  caption: {
    fontSize: "0.9em"
  }
});

export default style;
