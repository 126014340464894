import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/performance';
import 'firebase/compat/remote-config';
import {
  CollectionReference,
  DocumentReference,
  FirebaseFirestore
} from '@firebase/firestore-types';

firebase.initializeApp({
  apiKey: 'AIzaSyAodfgf7_RN2VuHw5eOp-HgT4LRSR9sTjo',
  authDomain: 'tagyp-4f031.firebaseapp.com',
  databaseURL: 'https://tagyp-4f031.firebaseio.com',
  projectId: 'tagyp-4f031',
  storageBucket: 'tagyp-4f031.appspot.com',
  messagingSenderId: '986916262422',
  appId: '1:986916262422:web:e30964d09f9f9c99429b37',
  measurementId: 'G-0JJNGWYVKM'
});

export const app = firebase;
export const analytics = firebase.analytics();
export const auth = firebase.auth?.();
export const firestore = firebase.firestore?.();
export const storage = firebase.storage?.();
export const functions = firebase.functions?.();
export const storageRef = storage?.ref();

firebase.performance?.();

export const remoteConfig = firebase.remoteConfig?.();

if (remoteConfig) {
  remoteConfig.settings.minimumFetchIntervalMillis = 3000;
}

export default firebase;

export function getDatabase(): firebase.database.Database {
  return firebase.database();
}

export function getFirestore(): FirebaseFirestore {
  if (firebase.firestore) {
    return firebase.firestore();
  }

  throw new Error('firestore-not-initialized');
}
export function getAuth(): firebase.auth.Auth {
  if (firebase.auth) {
    return firebase.auth();
  }

  throw new Error('auth-not-initialized');
}
export function getStorage(): firebase.storage.Reference {
  if (firebase.storage) {
    return firebase.storage().ref();
  }

  throw new Error('auth-not-initialized');
}

export function getFunctions(): firebase.functions.Functions {
  if (firebase.functions) {
    return firebase.functions();
  }

  throw new Error('functions-not-initialized');
}
export function getCurrentUser(): firebase.User | null {
  return getAuth().currentUser;
}
export function requireUser(): firebase.User {
  const user = getCurrentUser();
  if (user) {
    return user;
  }
  throw new Error('require-signin');
}
export function getColRef(collection: string): CollectionReference {
  return getFirestore().collection(collection);
}
export function getDocRef(
  collection: string,
  document?: string
): DocumentReference {
  if (collection && document) {
    return getColRef(collection).doc(document);
  }
  return getColRef(collection).doc();
}

export async function getIdToken() {
  const IdTokenResult = await requireUser().getIdToken();
  return IdTokenResult;
}

export async function getIdTokenIfLogin() {
  const user = auth.currentUser;

  if (user) {
    const token = await getIdToken();
    return token;
  }

  return null;
}
