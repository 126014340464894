import {getIdToken} from '../utils/firebase';
import {Device} from '../utils/types';
import fetch from '../utils/fetch';

export async function devicesShow(deviceId: string): Promise<Device> {
  const token = await getIdToken();
  const res = await fetch('devices/v1/get', token, {deviceId});

  return res.result;
}

export async function devicesList(id?: string): Promise<Device[]> {
  const token = await getIdToken();
  const res = await fetch('devices/v1/list', token, {userId: id});

  return res.result;
}

interface SetSOSData {
  id: string;
  sos1: string;
  email1: string;
  sos2: string;
  email2: string;
}

export async function devicesUpdateSOS(data: SetSOSData): Promise<void> {
  const token = await getIdToken();
  await fetch('devices/v1/setSOS', token, data);
}

export async function removeDevice(id: string) {
  const token = await getIdToken();
  await fetch('devices/v1/removeOwner', token, {id});
}

export async function registerDevice(data: {
  code: string;
  name: string;
  codEnt?: string
}): Promise<string> {
  const token = await getIdToken();
  const res = await fetch('devices/v1/setOwner', token, data);
  return res.result.id as string;
}

interface UpdateInfo {
  id: string;
  name: string;
  username: string;
  ci: string;
  genre: string;
  condition: string;
  dob: string;
}

export async function devicesUpdateInfo(data: UpdateInfo): Promise<void> {
  const token = await getIdToken();
  await fetch('devices/v1/updateInfo', token, data);
}

/**
 * Retorna los datos del dispositivo y la lista de alertas y SOS por fecha
 */
export async function getDateRangeRecords(deviceId: string, fromDate: string, toDate: string) {
  const token = await getIdToken();
  return fetch('devices/v1/date-range-records', token, {
    deviceId,
    fromDate,
    toDate,
  })
}

/**
 * Retorna la lista de dispositivos dee un perfil
 */
export async function getDevicesByProfile(profileId: string, includeClients: boolean) {
  const token = await getIdToken();
  return fetch('devices/v1/list-by-profile', token, {
    profileId,
    includeClients: true
  })
}

/**
 * Retorna la lista de alertas y SOS de un dispositivo
 */
export async function getDeviceAlerts(deviceId:string, includeUser: boolean, pageConfig: {
  alertPage: number,
  alertPerPage: number,
  sosPage: number,
  sosPerPage: number,
}) {
  const token = await getIdToken();
  const { alertPage, alertPerPage, sosPage, sosPerPage } = pageConfig
  return fetch('devices/v1/alerts', token, {
    deviceId,
    includeUser,
    alertPage,
    alertPerPage,
    sosPage,
    sosPerPage,
  })
}