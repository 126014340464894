import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './styles.module.css';
import {faAngleDown} from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { Roles, hasRole, hasRoleAdmin, hasRoleEmpresa } from '../../utils/roles';

interface Props {
  adminMenuOpen: boolean;
  setAdminMenuOpen: (open: boolean) => void;
}

export default function AdministracionMenu({ adminMenuOpen, setAdminMenuOpen }: Props) {
  const profile = useSelector((state: RootState) => state.profile.profile);

  return (
    <div className={styles.deviceSelector}>
      <div className={styles.deviceValue} onClick={() => setAdminMenuOpen(!adminMenuOpen)}>
        <span>Administración</span>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      { adminMenuOpen && (
        <div className={styles.deviceOptions}>
          { hasRoleAdmin(profile.roles) && (
            <Link to="/admin/users" onClick={() => setAdminMenuOpen(false)}>
              <center><span>Usuarios/roles</span></center>
            </Link>
          )}
          { hasRoleEmpresa(profile.roles) && (
            <Link to="/admin/clientes" onClick={() => setAdminMenuOpen(false)}>
              <center><span>Usuarios</span></center>
            </Link>
          )}
          { hasRoleEmpresa(profile.roles) && (
            <Link to="/admin/dashboard-cliente" onClick={() => setAdminMenuOpen(false)}>
              <center><span>Dashboard Clientes</span></center>
            </Link>
          )}
          { hasRole(profile.roles, [Roles.ADMIN, Roles.OPERADOR]) && (
            <Link to="/admin/dashboard-admin" onClick={() => setAdminMenuOpen(false)}>
              <center><span>Dashboard Admin</span></center>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}
