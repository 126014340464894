import React, { useState } from 'react';
import { RouteProps, useNavigate } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {signInWithEmail} from '../../../services/auth';
import Text from '../../Input/InputText';
import style from './style.module.css';
import { User } from 'firebase/auth'

const unexpected = 'An unexpected error ocurred!';

const errorMap: any = {
  'auth/invalid-email': 'Correo electrónico inválido',
  'auth/user-not-found': 'Usuario no existe',
  'auth/wrong-password': 'Contraseña incorrecta'
};

type LoginComponentProps = RouteProps & {
    logo: string,
    registerPath: string,
    recoverPath: string,
    onLoginSuccess?: (user: User) => void,
};

const LoginComponent = (props: LoginComponentProps) => {
    const [process, setProcess] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const { t } = useTranslation();
    const {registerPath, recoverPath, logo} = props;
    const navigate = useNavigate();

    const onLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
          e.preventDefault();
          setProcess('login');
          setMessage("");
          await signInWithEmail(email, password);
          navigate('/');
        } catch (error: any) {
          console.log(error);
          setProcess("");
          setMessage(errorMap[error.code] || error.message || unexpected)
        }
    };

    return (
        <div className={style.login}>
            <div className={style.logoContainer}>
            {logo ? <img src={logo} alt="" className={style.logo} /> : null}
            </div>

            <form className={style.form} onSubmit={onLogin}>
            <div>
                <Text
                id="email"
                label={t('email')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                value={email}
                inputClassname={style.input}
                />
                <br />
                <Text
                id="password"
                label={t('password')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                value={password}
                inputClassname={style.input}
                password
                />
            </div>
            <div className={style.recoverLinkContainer}>
                {recoverPath ? (
                <Link className={style.recoverLink} to={recoverPath}>
                    {t('forgotPassword')}
                </Link>
                ) : null}
            </div>
            <div className={style.buttonContainer}>
                <button className={style.sendBtn}>
                {process ? <FontAwesomeIcon icon={faSpinner} spin /> : t('login')}
                </button>
                {registerPath ? (
                <Link className={style.registerLink} to={registerPath}>
                    {t('dontHaveAccount')} <strong>{t('register')}</strong>
                </Link>
                ) : null}
            </div>

            <div className={style.errorContainer}>{message}</div>
            </form>
        </div>
    );
}

export default LoginComponent;
