import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {auth} from '../../../utils/firebase';
import Text from '../../Input/InputText';
import style from './style.module.css';

type Props = {
  loginPath: string,
  logo: string,
}

const Recover = (props: Props) => {
  const [process, setProcess] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const {loginPath, logo} = props;

  const onRecover = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      await auth.sendPasswordResetEmail(email);
      setProcess("");
      setStatus("success");
      setMessage("");
    } catch (error: any) {
      setProcess("");
      setStatus("failure");
      setMessage(error.message);
    }
  };

  const success = (
    <div className={style.success}>
      <p className={style.intructions}>{t('recoverSuccess')}</p>
      <Link to={loginPath} className={style.sendBtn}>
        {t('continue')}
      </Link>
    </div>
  );

  const form = (
    <form className={style.form} onSubmit={onRecover}>
      <p className={style.intructions}>{t('recoverInstructions')}</p>
      <div>
        <Text
          id="email"
          label={t('email')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          value={email}
          inputClassname={style.input}
        />
      </div>

      <div className={style.buttonContainer}>
        <button className={style.sendBtn}>
          {process ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            t('continue')
          )}
        </button>
      </div>

      <div className={style.errorContainer}>{message}</div>
    </form>
  );

  return (
    <div className={style.login}>
      {logo ? <img src={logo} alt="" className={style.logo} /> : null}
      {status === 'success' ? success : form}
    </div>
  );
}

export default Recover;
