import React, { useState } from 'react'
import Modal from 'react-modal'
import styles from './styles'
import InputSelect from '../../Input/InputSelect';
import InputText from '../../Input/InputText';
import { setAlertChecked } from '../../../services/heartRate';
import { healthCheckedStatusOpt } from '../../../utils/const';

export interface ModalAlertData {
  deviceId: string,
  alertId: string,
  name?: string,
  condition?: string,
  low: number,
  high: number,
  rhythm: number,
  tag?: string,
}

interface Props {
  isOpen: boolean,
  isLoading: boolean,
  data: ModalAlertData,
  onClose: () => void,
  afterSave: () => void,
  setIsLoading: (val: boolean) => void,
}

const ModalAlert = ({
  isOpen,
  data,
  onClose,
  afterSave,
  setIsLoading,
}: Props) => {
  const [ status, setStatus ] = useState("")
  const [ otro, setOtro ] = useState("")

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "50%"
    },
  };

  const closeModal = function() {
    setStatus("")
    setOtro("")
    onClose()
  }

  const saveModal = function() {
    if (!status || (status === "otro" && !otro?.trim())) {
      return
    }
    
    setIsLoading(true)
    return setAlertChecked(data.alertId, data.deviceId, status, otro || undefined)
      .then(r => {
        setStatus("")
        setOtro("")
        afterSave()
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false))
  }

  return <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Example Modal"
  >
    <center><h3>{ data.name }</h3></center>
    <div className={ styles.modalAlertGrid }>
      <div>Condición: { data.condition }</div>
      <div>Sistólica/Diastólica: { data.high }/{ data.low }</div>
      <div>Frecuencia: { data.rhythm }</div>
      <div>Usuario tag: { data.tag }</div>
      <div className={ styles.modalAlertDropdown }>
        Estatus:
        <InputSelect
          id="alertSelect"
          value={status}
          onChange={val => {
            if (otro) setOtro("")
            setStatus(val)
          }}
          options={[
            { label: "Seleccione", value: "" },
            ...healthCheckedStatusOpt
          ]}
        />
      </div>
      { status === "otro" && <div className={ styles.modalAlertOtro }>
          Especifique: <InputText id="alertOtro" value={otro} onChange={e => setOtro(e.target.value)}  />
        </div>
      }
      <div className={ styles.modalAlertBtnCont }>
        <button className={ styles.modalAlertBtn } onClick={closeModal}>Cancelar</button>
      </div>
      <div className={ styles.modalAlertBtnCont }>
        <button className={ styles.modalAlertBtn } onClick={saveModal}>Guardar</button>
      </div>
    </div>
  </Modal>
}

export default ModalAlert