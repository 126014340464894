import React from 'react';
import Layout from '../../components/Layout';
import styles from './styles.module.css';
import {HearRateReport} from '../../services/heartRate';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import {BackButton} from '../../components/BackButton';
import BoundsTable from './BoundsTable';
import Pression from './Pression';
import Rhythm from './Rhythm';
import ReportsTable from './ReportsTable';
import ReportsManualTable from './ReportsManualTable';

interface Props {
  loading: boolean;
  log: HearRateReport | null;
  backButtonPath?: string;
}

class HealthReport extends React.Component<Props> {
  render() {
    const {log, loading, backButtonPath} = this.props;

    if (loading) {
      return (
        <Layout>
          <div className={styles.container}>
            <div
              style={{
                fontSize: '4em',
                color: 'rgba(0, 0, 0, .3)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1em'
              }}>
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          </div>
        </Layout>
      );
    }

    if (!log) {
      return (
        <Layout>
          <div className={styles.container}>
            <div
              style={{
                fontSize: '2em',
                color: 'rgba(0, 0, 0, .3)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1em'
              }}>
              No se encontraron datos
            </div>
          </div>
        </Layout>
      );
    }

    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.average}>
            <div className={styles.header}>
              {backButtonPath ? <BackButton path={backButtonPath} /> : null}

              <div className={styles.time}>
                <div>Ultima actualización:</div>
                <div>
                  {log.last
                    ? moment(log.last.date).format('DD/MM/YY HH:mm')
                    : ''}
                </div>
              </div>
            </div>

            <div className={styles.averageValues}>
              <div className={styles.averageValueContainer}>
                <div className={styles.averageLabel}>SISTÓLICA</div>
                <div className={styles.averageValue}>
                  {log.data.high ? Math.floor(log.data.high) : 0}
                </div>
              </div>
              <div className={styles.averageValueContainer}>
                <div className={styles.averageLabel}>DIASTÓLICA</div>
                <div className={styles.averageValue}>
                  {log.data.low ? Math.floor(log.data.low) : 0}
                </div>
              </div>
              <div className={styles.averageValueContainer}>
                <div className={styles.averageLabel}>FRECUENCIA</div>
                <div className={styles.averageValue}>
                  {log.data.rhythm ? Math.floor(log.data.rhythm) : 0}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.graphContainer} key="hour">
            <Pression data={log} bound="higher" />
            <Pression data={log} bound="lower" />
            <Rhythm data={log} />
            <BoundsTable data={log} />
            <ReportsManualTable data={log.logsManual || []} />
            <ReportsTable data={log} />
          </div>
        </div>
      </Layout>
    );
  }
}

export default HealthReport;
