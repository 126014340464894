import {stylesheet} from 'typestyle';

const styles = stylesheet({
  mapContainer: {
    width: '100%',
    height: '100%',
    background: '#F8F9FA'
  },
  map: {
    width: '100%',
    height: '100%'
  }
});

export default styles;
