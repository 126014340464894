import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    width: '100%',
    marginTop: "15px",
  },
  title: {
    textAlign: "center",
    fontWeight: "normal"
  },
  graphContainer: {
    width: '100%',
    textAlign: "center",
  },
  graph: {
    width: "80%",
    margin: "auto"
  }
});

export default style;
