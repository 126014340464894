import React, {useEffect, useState} from 'react';
import Layout from '../../components/Layout';
import ProfilesForm from '../../components/ProfileForm';
import styles from './styles';
import {Route, Navigate, useNavigate, Routes} from 'react-router-dom';
import {RootState} from '../../reducers';
import {bindActionCreators} from '@reduxjs/toolkit';
import {connect, ConnectedProps, useDispatch, useSelector} from 'react-redux';
import {BackButton} from '../../components/BackButton';
import {
  getProfileDefaultState,
  profileShow,
  profileUpdate
} from '../../services/profiles';
import {setProfile} from '../../reducers/profile';
import { Profile as ProfileType } from '../../utils/types';

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const connector = connect(
  (state: RootState) => ({
    devices: state.devices,
    auth: state.auth
  }),
  (dispatch) => bindActionCreators({}, dispatch)
);

const Profile = ({auth}: Props) => {
  const [profile, changeProfile] = useState(getProfileDefaultState());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const devices = useSelector((state: RootState) => state.devices);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function load() {
      try {
        setLoading(true);
        setMessage('');
        const data = await profileShow();
        changeProfile(data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        setMessage(err.message);
      }
    }

    load();
  }, []);

  async function save() {
    try {
      setLoading(true);
      setMessage('');
      const data = await profileUpdate(profile);
      dispatch(setProfile(data));
      setMessage('Guardado con éxito');
      setLoading(false);

      if (!devices.devices.length) {
        navigate('/dispositivos/nuevo');
      }
    } catch (err: any) {
      setLoading(false);
      setMessage(err.message);
    }
  }

  if (!auth.user) {
    return null;
  }

  return (
    <Layout background="#fcfcfc">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <BackButton />
        </div>
        <div className={styles.wrapper}>
            <Routes>
                <Route path="/personal" element={
                    <ProfilesForm
                        loading={loading}
                        value={profile}
                        onChange={(e: ProfileType) => {
                        setMessage('');
                        changeProfile(e);
                        }}
                        onSubmit={save}
                        message={message}
                    />
                }/>
                <Route path="*" element={<Navigate to="/perfil/personal" replace />} />
            </Routes>
        </div>
      </div>
    </Layout>
  );
};

export default connector(Profile);
