import React, {useEffect, useState} from 'react';
import styles from './styles';
import Layout from '../../components/Layout';
import { BackButton } from '../../components/BackButton';
import { getIdToken } from '../../utils/firebase';
import DeviceSel from '../../components/DashboardCliente/DeviceSelector/DeviceSel';
import DeviceTable from '../../components/DashboardCliente/DeviceTable/DeviceTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { Profile } from '../../utils/types';
import { useParams } from 'react-router-dom';
import { profileShow } from '../../services/profiles';
import { hasRoleAdmin } from '../../utils/roles';

const DashboardCliente = () => {
  const [ userProfile, setUserProfile ] = useState<Profile>()
  const logedProfile = useSelector((state: RootState) => state.profile.profile)
  const { profileId } = useParams()
  const [ token, setToken ] = useState("")
  const [ deviceId, setDeviceId ] = useState("")
  if (!token) getIdToken().then(t => setToken(t))

  const isAdmin = hasRoleAdmin(logedProfile.roles)

  useEffect(() => {
    if (profileId && isAdmin) {
      if (token) {
        profileShow(profileId).then(p => setUserProfile(p))
          .catch(err => console.error(err))
      }
    } else {
      setUserProfile(logedProfile)
    }
  }, [ token, profileId, logedProfile, isAdmin ])

  return (
    <Layout background="#fcfcfc">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <BackButton path={isAdmin ? "/admin/dashboard-admin" : undefined} />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.list}>
            <center><h3 className={styles.mainTitle}>Dashboard{ isAdmin && " Cliente"}</h3></center>
            <center><h3 className={styles.title}>{ [userProfile?.firstName, userProfile?.firstSurname].join(" ") }</h3></center>
            {
              userProfile && <DeviceSel
                profileId={userProfile.id}
                deviceId={deviceId}
                setDeviceId={setDeviceId}
                />
            }
            { userProfile && <DeviceTable token={token} profileId={userProfile.id} setDeviceId={setDeviceId} /> }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DashboardCliente;
