import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Outlet, RouteProps, useLocation, useNavigate} from 'react-router-dom';
import {RootState} from '../../reducers';
import styles from './styles.module.css';

const LoadingWrapper = ({ children }: RouteProps) => {
  const auth = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.status === 'error' || (auth.status !== 'loading' && !auth.user)) {
        navigate('/iniciar');
    }
  }, [auth.status, auth.user, location.pathname, navigate]);

  if (auth.status === 'loading') {
    return (
      <div className={styles.loaderContainer}>
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );
  }

  return <Outlet />;
};

export default LoadingWrapper;
