import React, { useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import InputText from '../../components/Input/InputText';
import styles from './styles';
import {Device} from '../../utils/types';
import {devicesUpdateInfo} from '../../services/devices';
import InputSelect from '../../components/Input/InputSelect';
import ReactDatePicker from '../../components/Input/ReactDatePicker';

interface SOSProps {
  id: string;
  data: Device;
}

const InfoConfig = ({ id, data }: SOSProps) => {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState(data?.name || "");
  const [username, setUsername] = useState(data?.username || "");
  const [ci, setCi] = useState(data?.ci || "");
  const [genre, setGenre] = useState(data.genre || "");
  const [condition, setCondition] = useState(data?.condition || "");
  const [dob, setDob] = useState(data?.dob || "");

  const save = async () => {
    try {
      setStatus('saving');
      setMessage('');
      await devicesUpdateInfo({
        id,
        name,
        username,
        ci,
        genre,
        condition,
        dob
      });
      setStatus('loaded');
    } catch (error: any) {
      setStatus('error');
      setMessage(error.message || 'Ocurrio un error inesperado');
      console.log(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span>Información</span>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '1em'
        }}>
        <InputText
          id="name"
          label="Nombre del dispositivo"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <InputText
          id="username"
          label="Nombre de la persona"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <InputText
          id="ci"
          label="Cédula"
          value={ci}
          onChange={(e) => setCi(e.target.value)}
        />
        <InputSelect
          id="genre"
          label="Género"
          value={genre}
          onChange={(value) => setGenre(value)}
          options={[
            {label: 'Femenino', value: 'female'},
            {label: 'Masculino', value: 'male'}
          ]}
        />
        <ReactDatePicker
          id="dob"
          label="Fecha de nacimiento (día / mes /año)"
          value={dob ? new Date(dob) : null}
          onChange={(value) => setDob(value.toISOString())}
        />
        <InputText
          id="condition"
          label="Condición médica"
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
        />
      </div>
      <div className={styles.buttons}>
        <div className={styles.message}>{message}</div>
        <button className={styles.button} onClick={save}>
          {status === 'saving' || status === 'loading' ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <span>Guardar</span>
          )}
        </button>
      </div>
    </div>
  );
}

export default InfoConfig