import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    width: '100%',
    marginTop: "15px",
  },
  table: {
    width: "100%",
    border:"1px solid darkgray",
    borderCollapse: "collapse",
    marginTop: "5px",
  },
  tableTh: {
    padding: "3px",
    borderBottom: "1px solid darkgray",
    color: "gray",
  },
  td: {
    border: "1px solid darkgray",
    padding: "3px"
  },
  title: {
    textAlign: "center",
    fontWeight: "normal"
  },
  tdCenter: {
    border: "1px solid darkgray",
    padding: "3px",
    textAlign: "center",
  },
  clickable: {
    cursor: "pointer"
  }
});

export default style;
