import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    background: "lightgray",
    opacity: "0.9",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1000
  },
  innerContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  spinner: {
    fontSize: "3em",
    color: "#666",
  },
});

export default style;
