import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    width: '100%',
    height: '100%'
  },
  wrapper: {
    maxWidth: 900,
    margin: '0 auto'
  },
  list: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflowY: "scroll",
    padding: "2em",
    background: "#FFF",
    boxShadow: "1px 1px 5px 1px rgba(0,0,0,.05)",
    borderRadius: 4,
  },
  table: {
    border:"1px solid darkgray",
    borderCollapse: "collapse",
  },
  tableTh: {
    width: "50%",
    padding: "3px"
  },
  td: {
    border: "1px solid darkgray",
    padding: "3px"
  },
  delete: {
    color: "red",
    cursor: "pointer",
    marginLeft: "3px"
  },
  mainTitle: {
    marginBottom: "5px",
  },
  title: {
    fontWeight: "normal",
  }
});

export default style;
