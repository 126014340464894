import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getWeeklyHealthReport, HearRateReport} from '../../services/heartRate';
import HealthReport from '../Heart/HealthReport';

const WeeklyReportParser = () => {
  const [loading, setLoading] = useState(true);
  const [log, setLog] = useState<HearRateReport | null>(null);
  const { token } = useParams();

  useEffect(() => {
    async function load() {
      try {
        setLoading(true);
        const data = await getWeeklyHealthReport(token as string);

        setLog(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    load();
  }, [token]);

  return <HealthReport loading={loading} log={log} />;
};

export default WeeklyReportParser;
