import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import styles from './styles';

export interface MapContextState {
  instance: google.maps.Map | null;
}

export interface MapContextProps {
  map: MapContextState;
}

export const MapContext = React.createContext<MapContextState>({
  instance: null
});

export function withMap(Wrapped: React.ComponentType<any>) {
  return function MapContextWrapper(props: any) {
    const mapContext = useContext(MapContext);
    return (
      <Wrapped map={mapContext} {...props} />
    );
  };
}

type MapProps = PropsWithChildren & {
  center: {lat: number; lng: number};
  zoom?: number;
}

const Map = ({ center, zoom, children }: MapProps) => {
    const [instance, setInstance] = useState<google.maps.Map<HTMLHtmlElement> | null>(null);

    useEffect(() => {
      if (!instance) {
        const div = document.getElementById('mapContainer');
          const instance = new google.maps.Map(div as HTMLHtmlElement, {
              center: center,
              zoom: zoom || 10
          });
          setInstance(instance);
      } else {
        instance.setCenter(center);
        instance.setZoom(zoom || 10);
      }
    }, [instance, center, zoom]);

    return (
      <MapContext.Provider value={{instance}}>
        <div id="mapContainer" className={styles.mapContainer}>
          <div id="map" className={styles.map} />
          {instance ? children : null}
        </div>
      </MapContext.Provider>
    );
}

export default Map;
