import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styles from './styles';

interface Props {
  id: string;
  value?: Date | null;
  label?: string;
  placeholder?: string;
  password?: boolean;
  onChange?: (value: Date, id?: string) => void;
  onHelp?: () => void;
  minDate?: Date;
}

const ReactDatePicker = ({ id, label, value, onChange }: Props) => {
  const [text, setText] = useState("");

  useEffect(() => {
    if (moment(value).isValid()) {
      setText(moment(value).format('DD/MM/YYYY'));
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const onTextInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const date = moment(text, 'DD/MM/YYYY');

    if (date.isValid()) {
      const date = moment(text, 'DD/MM/YYYY');
      setText(date.format('DD/MM/YYYY'));
      return onChange?.(date.toDate(), id);
    }

    const valid = moment();
    setText(valid.format('DD/MM/YYYY'));

    return onChange?.(valid.toDate(), id);
  };

  return (
    <div className={styles.container}>
      <div className={styles.labelContainer}>
        {label ? (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        ) : null}
      </div>
      <input
        id={id}
        className={styles.input}
        value={text}
        onChange={onTextChange}
        placeholder="DD/MM/YYYY"
        onBlur={onTextInputBlur}
        autoComplete="off"
      />
    </div>
  );
}

export default ReactDatePicker;