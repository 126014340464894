import React, { useEffect, useState } from 'react';
import { Chart as ChartType, ChartDataset } from 'chart.js';
import Chart from 'chart.js/auto';
import { uid } from 'uid';
import styles from './styles';

interface Props {
  labels: string[];
  datasets: ChartDataset[];
}

const Graph = (props: Props) => {
    const [url, setUrl] = useState("");
    const [graph, setGraph] = useState<ChartType>();
    const chartID = uid();

    const download = () => {
        const canvas = document.getElementById(chartID) as HTMLCanvasElement;
        if (canvas) {
            const url = canvas.toDataURL('image/jpg');
            setUrl(url);
        }
    };

    useEffect(() => {
        const canvas = document.getElementById(chartID) as HTMLCanvasElement;

        const chart = new Chart(canvas, {
            type: 'line',
            data: {
                labels: props.labels,
                datasets: props.datasets
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },
                animation: {
                    onComplete: download
                }
            },
            plugins: [{
                id: 'plugin1',
                beforeDraw: function (chartInstance) {
                    const ctx = chartInstance.ctx;
                    if (ctx) {
                        ctx.fillStyle = 'white';
                        ctx.fillRect(0, 0, canvas.width, canvas.height);
                    }
                }
            }]
        });

        setGraph(chart);

        return () => {
            graph?.destroy();
            setGraph(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (graph) {
            graph.data.datasets = props.datasets;
            graph.data.labels = props.labels
        }
    }, [graph, props.datasets, props.labels]);

    return (
      <>
        <canvas id={chartID} className={styles.graph}></canvas>
        <div className={styles.buttons} style={{display: 'none'}}>
          {url ? (
            <a
              download="ChartImage.jpg"
              href={url}
              className={styles.button}>
              Descargar
            </a>
          ) : null}
        </div>
      </>
    );
}

export default Graph;
