export enum Roles {
  ADMIN = "admin",
  CLIENTE = "cliente",
  EMPRESA = "empresa",
  OPERADOR = "operador",
}

const roles = [
  { id: Roles.CLIENTE, label: "Cliente" },
  { id: Roles.EMPRESA, label: "Empresa" },
  { id: Roles.ADMIN, label: "Admin" },
  { id: Roles.OPERADOR, label: "Operador" },
];


export const rolesForSelOpt = roles.map(r => { return { value: r.id, label: r.label }})
export const getRoleLabel = (r: string) => roles.filter(role => role.id === r)?.shift()?.label || ""

export const hasRole = (roles: string[], toSearch: string[]) => roles.filter(r => toSearch.includes(r))?.length ? true : false
export const hasRoleAdmin = (roles: string[]) => hasRole(roles, [Roles.ADMIN])
export const hasRoleEmpresa = (roles: string[]) => hasRole(roles, [Roles.EMPRESA])