import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './styles.module.css';
import {Device} from '../../utils/types';
import {faAngleDown} from '@fortawesome/pro-regular-svg-icons';
import {Link, useNavigate, useParams} from 'react-router-dom';

interface Props {
  current?: Device | null;
  devices: Device[];
  setCurrentDevice: (device: Device) => void;
}

export default function DeviceSelector(props: Props) {
  const navigate = useNavigate();
  const { id: currentDeviceId } = useParams();
  const [open, setOpen] = useState(false);

  const {devices} = props;

  const current = devices.find((d) => d.id === currentDeviceId);

  if (!devices.length) {
    return null;
  }

  return (
    <div className={styles.deviceSelector}>
      <div className={styles.deviceValue} onClick={() => setOpen(!open)}>
        <span>{current?.name || ''}</span>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {open ? (
        <div className={styles.deviceOptions}>
          {devices.map((device) => (
            <button
              key={device.id}
              onClick={() => {
                props.setCurrentDevice(device)
                navigate("/inicio/" + device.id);
                setOpen(false);
              }}>
              <span>{device.name}</span>
            </button>
          ))}
          <Link to="/dispositivos/nuevo" onClick={() => setOpen(false)}>
            <span>Nuevo</span>
          </Link>
        </div>
      ) : null}
    </div>
  );
}
