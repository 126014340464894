import React from 'react';
import styles from './styles'

interface PagerParams {
  currentPage: number,
  totalPages: number,
  numPagesAtSides?: number,
  setPage: any,
}

const Pager = ({ currentPage, totalPages, numPagesAtSides=3, setPage }: PagerParams) => {
  const countAtSidesLessThan = numPagesAtSides
  const pages: (number|string)[] = [1]
  const totalPagesCeil = Math.ceil(totalPages)

  if (currentPage-countAtSidesLessThan > 2) {
    pages.push("...")
  }

  for (let i=currentPage-countAtSidesLessThan; i < currentPage; i++) {
    if (i > 1) pages.push(i)
  }

  if (currentPage !== 1) pages.push(currentPage)

  for (let i=currentPage+1; i < totalPagesCeil && i < currentPage+countAtSidesLessThan+1; i++) {
    pages.push(i)
  }

  if (currentPage+countAtSidesLessThan+1 < totalPagesCeil) pages.push("...")
  if (currentPage < totalPagesCeil) pages.push(totalPagesCeil)

  return (
    pages.map((p, pIdx) => <div key={pIdx} style={{display:"inline-block", margin: "0 3px"}}>{
      (p !== currentPage && typeof p == "number")
        ? <button key={p} type="button" onClick={() => setPage(p)} className={styles.button}>{p}</button>
        : <button key={p} type="button" className={styles.buttonActive}>{p}</button>
    }</div>)
  );
}

export default Pager