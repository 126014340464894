import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {useParams} from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux';
import Layout from '../../components/Layout';
import {getLocations, LocationLog} from '../../services/location';
import Map from '../../components/Map';
import Marker from '../../components/Map/Marker';
import {BackButton} from '../../components/BackButton';
import {RootState} from '../../reducers';
import styles from './styles.module.css';
import {uniqBy} from 'lodash';

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const connector = connect((state: RootState) => ({
  devices: state.devices,
  auth: state.auth
}));

const Locations = ({ auth }: Props) => {
  const [status, setStatus] = useState("loading");
  const [locations, setLocations] = useState<LocationLog[]>([]);
  const [selected, setSelected] = useState<LocationLog>();
  const [timer, setTimer] = useState<number>();
  const { id } = useParams();

  useEffect(() => {
    if (timer) clearInterval(timer);

    const load = async (limit: number = 40) => {
      try {
        const deviceId = id!;
        setStatus('loading');
        const update = await getLocations({deviceId, limit});
        const theLocations = uniqBy([...update, ...locations], 'id');

        setStatus('loaded');
        setLocations(theLocations)
        setSelected(theLocations[0]);
      } catch (error) {
        console.log(error);
        setStatus('error');
      }
    };

    load().then(() => {
      setTimer(window.setInterval(() => { load() }, 10000));
    })

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (auth.status === 'loading' && status === 'loading') {
    return (
      <Layout>
        <div className={styles.container}>
          <div
            style={{
              fontSize: '4em',
              color: 'rgba(0, 0, 0, .3)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1em'
            }}>
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton path={`/inicio/${id}`} />
        </div>
        <div className={styles.content}>
          <div className={styles.list}>
            {locations.map((log) => (
              <button
                onClick={() => setSelected(log)}
                key={log.id}
                className={styles.log}
                style={{
                  background:
                    selected?.id === log.id ? 'rgba(0, 0, 0, .05)' : '#FFF'
                }}>
                <div>{`Fecha: ${moment(log.deviceTimestamp).format(
                  'DD/MM/YYYY HH:mm:ss'
                )}`}</div>
                <div>{`Coordenadas: ${log.lng}, ${log.lat}`}</div>
                <div>{`Satélites: ${log.satelites}`}</div>
                <div>{`GSM: ${log.gsm}`}</div>
                <div>{`Batería: ${log.power}`}</div>
              </button>
            ))}
          </div>

          <div className={styles.mapContainer}>
            {selected ? (
              <Map center={{lat: selected.lat, lng: selected.lng}} zoom={17}>
                <Marker
                  center={{
                    lat: selected.lat,
                    lng: selected.lng
                  }}
                  label={''}
                />
              </Map>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default connector(Locations);
