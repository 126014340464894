import {getIdToken} from '../utils/firebase';
import fetch from '../utils/fetch';
import {hexEncode} from '../utils/hex';

export async function command(id: string, commandString: string[]) {
  const token = await getIdToken();
  await fetch('commands/v1/command', token, {id, commandString});
}

export async function find(id: string) {
  await command(id, ['FIND']);
}

export async function poweroff(id: string) {
  await command(id, ['POWEROFF']);
}

export async function reset(id: string) {
  await command(id, ['RESET']);
}

export async function message(id: string, text: string) {
  await command(id, ['MESSAGE', hexEncode(text)]);
}

export async function call(id: string, number: string) {
  await command(id, ['CALL', number]);
}

export async function monitor(id: string) {
  await command(id, ['MONITOR']);
}

export async function ubication(id: string) {
  await command(id, ['CR']);
}

// low batery sms alarm status
export async function lowBatAlarm(id: string, status: number) {
  await command(id, ['LOWBAT', status + '']);
}

// sos sms alarm status
export async function sosAlarm(id: string, status: number) {
  await command(id, ['SOSSMS', status + '']);
}

// get device params
export async function ts(id: string) {
  await command(id, ['TS']);
}

// init heart reate & pressure (only newer model)
export async function setHrt(id: string) {
  await command(id, ['heart']);
}

// init heart reate & pressure
export async function startHrt(id: string) {
  await command(id, ['hrtstart', '1']);
}
