import moment from 'moment';
import React from 'react';
import {HearRateReport} from '../../../services/heartRate';
import styles from './styles.module.css';

interface Props {
  data: HearRateReport;
}

const ReportsTable = ({data}: Props) => {
  const days = data.logs;

  return (
    <div className={styles.boundsTable}>
      <div className={styles.title}>
        <span>Mediciones automáticas</span>
      </div>
      <div className={styles.listHeader}>
        <div>Fecha</div>
        <div>Sistólica</div>
        <div>Diastólica</div>
        <div>Frecuencia</div>
        <div>Oxigenación</div>
        <div>Condición</div>
      </div>
      <div className={styles.body}>
        {days.map((value, i) => (
          <div key={i} className={styles.listRow}>
            <div>{moment(value.date).format('YYYY-MM-DD HH:mm')}</div>
            <div>{value.high}</div>
            <div>{value.low}</div>
            <div>{value.rhythm}</div>
            <div>{value.oxygen || "-"}</div>
            <div>{value.status}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportsTable;
