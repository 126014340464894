import React from 'react';
import styles from './styles.module.css';
import logo from '../../assets/logos/logo01.png';
import Layout from '../../components/Layout';
import Recover from '../../components/Auth/Recover';
import { withRouter } from '../../utils/withRouter';

const Login = () => (
  <Layout>
    <div className={styles.container}>
        <Recover logo={logo} loginPath="/iniciar" />
    </div>
  </Layout>
);

export default withRouter(Login);
