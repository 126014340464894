import React from 'react';
import {HearRateReport} from '../../../services/heartRate';
import styles from './styles.module.css';

interface Props {
  data: HearRateReport;
}

const BoundsTable = ({data}: Props) => {
  const days = data.bounds;

  return (
    <div className={styles.boundsTable}>
      <div className={styles.title}>
        <span>Mediciones Máximas y Mínimas Diarias</span>
      </div>
      <div className={styles.listHeader}>
        <div>Fecha</div>
        <div>Max sistólica</div>
        <div>Min sistólica</div>
        <div>Max diastólica</div>
        <div>Min diastólica</div>
        <div>Max Frecuencia</div>
        <div>Min Frecuencia</div>
      </div>
      <div className={styles.body}>
        {days.map(([key, value]) => (
          <div key={key} className={styles.listRow}>
            <div>{key}</div>
            <div>{value.maximumHigh}</div>
            <div>{value.minimumHigh}</div>
            <div>{value.maximumLow}</div>
            <div>{value.minimumLow}</div>
            <div>{value.maximumRhythm}</div>
            <div>{value.minimumRhythm}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BoundsTable;
