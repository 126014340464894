import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {RootState} from '../../reducers';
import styles from './styles.module.css';
import { Roles, hasRole } from '../../utils/roles';

const Boot = () => {
  const devices = useSelector((state: RootState) => state.devices);
  const profile = useSelector((state: RootState) => state.profile);

  if (devices.status !== 'loaded' || profile.status !== 'loaded') {
    return (
      <div className={styles.loaderContainer}>
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );
  }

  if (hasRole(profile.profile.roles, [Roles.ADMIN, Roles.EMPRESA, Roles.OPERADOR])) {
    return <Navigate to={'/seleccion-inicio'} />;
  }

  if (
    !profile.profile?.id ||
    !profile.profile?.firstName ||
    !profile.profile?.firstSurname
  ) {
    return <Navigate to="/perfil/personal" replace />;
  }

  if (!devices.devices.length) {
    return <Navigate to="/dispositivos/nuevo" />;
  }

  if (devices.devices[0]?.id) {
    return <Navigate to={`/inicio/${devices.devices[0].id}`} />;
  }

  return <div>Boot</div>;
};

export default Boot;
