import { useEffect, useState } from 'react';
import {withMap, MapContextProps} from '../index';

interface MarkerProps extends MapContextProps {
  center: {lat: number; lng: number};
  label?: string;
}

const Marker = ({ map, center, label }: MarkerProps) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (map && center) {
            setMarker(new google.maps.Marker({
                position: center,
                map: map.instance!,
                label: label || ''
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        marker?.setPosition(center);
    }, [marker, center]);

    return null;
}

export default withMap(Marker);
