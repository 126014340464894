import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {devicesList} from '../services/devices';
import {Device} from '../utils/types';

type SliceState = {
  status: string;
  current: Device | null;
  data: Device[];
};

const initialState: SliceState = {
  status: '',
  current: null,
  data: []
};

export const fetchAdminDevices = createAsyncThunk(
  'adminDevices/list',
  async (_, api) => {
    try {
      const response = await devicesList();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const adminDevicesSlice = createSlice({
  name: 'adminDevices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdminDevices.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(fetchAdminDevices.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.data = action.payload;

      if (!action.payload.find((i) => i.id === state.current?.id)) {
        state.current = action.payload[0] || null;
      }
    });
  }
});

export default adminDevicesSlice.reducer;
