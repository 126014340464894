import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  getProfileDefaultState,
  profileShow as show
} from '../services/profiles';
import {Profile} from '../utils/types';

type SliceState = {
  status: 'loading' | 'loaded' | 'error';
  profile: Profile;
};

const initialState: SliceState = {
  status: 'loading',
  profile: getProfileDefaultState()
};

export const profileShow = createAsyncThunk('profile/show', async () => {
  try {
    const response = await show();
    return response;
  } catch (error) {
    throw error;
  }
});

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<Profile>) {
      state.status = 'loaded';
      state.profile = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(profileShow.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(profileShow.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.profile = action.payload;
    });
  }
});

export const {setProfile} = profileSlice.actions;

export default profileSlice.reducer;
