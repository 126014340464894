import {stylesheet} from 'typestyle';

const styles = stylesheet({
  graph: {
    width: '100%'
  },
  button: {
    color: '#666',
    padding: '1em 2em',
    border: '1px solid rgba(0, 0, 0, .1)',
    borderRadius: 4,
    width: '10em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1em 2em'
  }
});

export default styles;
