import get from 'lodash/get';
import moment from 'moment';
import {dateFromTimestamp} from '../utils/time';
import {getIdToken} from '../utils/firebase';
import fetch from '../utils/fetch';

export interface HeartRateLog {
  id: string;
  deviceId: string;
  amount: number;
  high: number;
  low: number;
  rhythm: number;
  oxygen?: number;
  status: string;
  auto?: boolean;
  date: Date;
}

export function parseLogData(data: unknown) {
  const theDate = get(data, 'date', null);
  return {
    date: (theDate && dateFromTimestamp(theDate)) || null,
    day: get(data, 'day', ''),
    dayOfYear: get(data, 'dayOfYear', ''),
    hour: get(data, 'hour', ''),
    health: get(data, 'health', ''),
    high: get(data, 'high', ''),
    low: get(data, 'low', ''),
    rhythm: get(data, 'rhythm', ''),
    oxygen: get(data, 'oxygen', ''),
  };
}

export interface HearRateReport {
  data: {
    rhythm: number;
    high: number;
    low: number;
  };
  logs: HeartRateLog[];
  logsManual?: HeartRateLog[];
  bounds: BoundsArray;
  last: HeartRateLog;
}

export async function getHeartRate(id: string): Promise<HearRateReport> {
  const token = await getIdToken();
  const res = await fetch('salud/v1/list', token, {
    deviceId: id,
    limit: 100,
    manual: false
  });
  const resManual = await fetch('salud/v1/list', token, {
    deviceId: id,
    limit: 100,
    manual: true
  });

  return {
    ...prepareHealthData([
      ...(res.result || []),
      ...(resManual.result || []),
    ]),
  };
}

export function prepareHealthData(data: HeartRateLog[]) {
  const last = data.sort((a, b) => moment(b.date).diff(moment(a.date)))[0];
  const dataAuto = data.filter(d => d.auto !== false);
  
  return {
    data: {
      rhythm:
        data.reduce(
          (acc: number, cur: HeartRateLog) => acc + Number(cur.rhythm),
          0
        ) / data.length,
      high:
        data.reduce(
          (acc: number, cur: HeartRateLog) => acc + Number(cur.high),
          0
        ) / data.length,
      low:
        data.reduce(
          (acc: number, cur: HeartRateLog) => acc + Number(cur.low),
          0
        ) / data.length
    },
    logs: dataAuto,
    logsManual: data.filter((d: any) => d.auto === false),
    bounds: getBoundsByDay(dataAuto),
    last
  };
}

export function getBoundsByDay(data: HeartRateLog[]): BoundsArray {
  const days = data.reduce<Record<string, HeartRateLog[]>>((a, b) => {
    const key = moment(b.date).format('YYYY-MM-DD');
    if (a[key]) {
      return {...a, [key]: [...a[key], b]};
    } else {
      return {...a, [key]: [b]};
    }
  }, {});

  const bounds = Object.entries(days).reduce<{[key: string]: Bounds}>(
    (acc, [key, logs]) => ({...acc, [key]: getDataBounds(logs)}),
    {}
  );

  const boundsArray = Object.entries(bounds).sort((a, b) =>
    moment(b[0], 'YYYY-MM-DD').diff(moment(a[0], 'YYYY-MM-DD'))
  );

  return boundsArray;
}

export interface Bounds {
  maximumHigh: number;
  maximumLow: number;
  minimumHigh: number;
  minimumLow: number;
  maximumRhythm: number;
  minimumRhythm: number;
}

export type BoundsArray = [string, Bounds][];

function getDataBounds(data: HeartRateLog[]): Bounds {
  const maximumHigh = Math.max(...data.map((l) => l.high));
  const minimumHigh = Math.min(...data.map((l) => l.high));

  const maximumLow = Math.max(...data.map((l) => l.low));
  const minimumLow = Math.min(...data.map((l) => l.low));

  const maximumRhythm = Math.max(...data.map((l) => l.rhythm));
  const minimumRhythm = Math.min(...data.map((l) => l.rhythm));

  return {
    maximumHigh,
    maximumLow,
    minimumHigh,
    minimumLow,
    maximumRhythm,
    minimumRhythm
  };
}

export async function getWeeklyHealthReport(token: string) {
  const res = await fetch('reports/v1/getHealthReport', null, {token});
  return prepareHealthData(res.result || []);
}

export async function setAlertChecked(alertId: string, deviceId: string, checkedStatus: string, checkedOtro?: string) {
  const token = await getIdToken();
  await fetch('salud/v1/set-checked', token, {
    deviceId,
    alertId,
    checkedStatus,
    checkedOtro,
  });
}