import React from 'react';
import {RouteProps, useNavigate} from 'react-router-dom';
import logo from '../../assets/logos/logo01.png';
import Layout from '../../components/Layout';
import RegisterComponent from '../../components/Auth/Register';
import styles from './styles.module.css';
import { withRouter } from '../../utils/withRouter';

const Register = (props: RouteProps) => {
    const navigate = useNavigate();
    return (
        <Layout>
            <div className={styles.container}>
                <RegisterComponent
                    logo={logo}
                    loginPath="/iniciar"
                    onRegisterSuccess={() => navigate('/perfil/personal')}
                />
            </div>
        </Layout>
    );
};

export default withRouter(Register);
