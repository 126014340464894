import get from 'lodash/get';
import {getIdToken} from '../utils/firebase';
import {Profile} from '../utils/types';
import {isString} from '../utils/validation';
import fetch from '../utils/fetch';
import moment from 'moment';
import { Roles } from '../utils/roles';

export function getProfileDefaultState(data?: unknown): Profile {
  return {
    id: get(data, 'id', ''),
    firstName: get(data, 'firstName', ''),
    firstSurname: get(data, 'firstSurname', ''),
    secondSurname: get(data, 'secondSurname', ''),
    dob: get(data, 'dob', ''),
    genre: get(data, 'genre', ''),
    martialStatus: get(data, 'martialStatus', 'single'),
    primaryPhone: get(data, 'primaryPhone', ''),
    secundaryPhone: get(data, 'secundaryPhone', ''),
    email: get(data, 'email', ''),
    country: get(data, 'country', 'VE'),
    state: get(data, 'state', ''),
    municipality: get(data, 'municipality', ''),
    address: get(data, 'address', ''),
    zipCode: get(data, 'zipCode', ''),
    roles: get(data, 'roles', []),
    code: get(data, 'code', ''),
  };
}

export async function profileShow(id?: string): Promise<Profile> {
  const token = await getIdToken();
  const res = await fetch('profiles/v1/show', token, { id });
  return getProfileDefaultState(res.result) as Profile;
}

export async function profileUpdate(data: Partial<Profile>): Promise<Profile> {
  const body: any = {};

  if (isString(data.firstName)) {
    body.firstName = data.firstName;
  }
  if (isString(data.firstSurname)) {
    body.firstSurname = data.firstSurname;
  }
  if (isString(data.secondSurname)) {
    body.secondSurname = data.secondSurname;
  }
  if (isString(data.dob)) {
    body.dob = data.dob;
  }
  if (isString(data.genre)) {
    body.genre = data.genre;
  }
  if (data.martialStatus) {
    body.martialStatus = data.martialStatus;
  }
  if (isString(data.country)) {
    body.country = data.country;
  }
  if (isString(data.state)) {
    body.state = data.state;
  }
  if (isString(data.municipality)) {
    body.municipality = data.municipality;
  }
  if (isString(data.address)) {
    body.address = data.address;
  }
  if (isString(data.zipCode)) {
    body.zipCode = data.zipCode;
  }
  if (isString(data.primaryPhone)) {
    body.primaryPhone = data.primaryPhone;
  }
  if (isString(data.secundaryPhone)) {
    body.secundaryPhone = data.secundaryPhone;
  }

  const token = await getIdToken();
  const res = await fetch('profiles/v1/update', token, body);
  if (res.result._id && !res.result.id) res.result.id = res.result._id.toString();

  return res.result as Profile;
}

interface initializeProfileInput {
  firstName: string;
}

export async function profileInitialize(
  data: initializeProfileInput
): Promise<void> {
  const token = await getIdToken();
  await fetch('profiles/v1/create', token, data);
}

export interface ProfileListParams {
  page: number,
  search?: string,
  role?: Roles | "",
}

export async function profilesList(params: ProfileListParams): Promise<{ total: number, data: Profile[] }> {
  const { page, search, role } = params;
  const token = await getIdToken();
  const res = await fetch('profiles/v1/list', token, {
    page,
    ...(search && { search }),
    ...(role && { role }),
  });
  return { total: res.result.total, data: res.result.data };
}

export async function setRoles(profileId: string, roles: string[], code: string) {
  const token = await getIdToken();
  return fetch('profiles/v1/set-roles', token, { id: profileId, roles, code });
}

interface ProfilesDashboardDevice {
  id: string,
  imei: string,
  name: string,
  sosPending: number,
  alertsPending: number,
}
export interface ProfilesDashboard {
  _id: string,
  firstName: string,
  firstSurname: string,
  devices: ProfilesDashboardDevice[],
}

interface SearchFilter {
  tipoUsu?: string,
  searchName?: string,
}

export async function profilesListDashboard(page: number, perPage: number, filter: SearchFilter): Promise<{ total: number, data: ProfilesDashboard[] }> {
  const token = await getIdToken();
  const pendingAlertFrom = moment().startOf("week").format("Y-MM-DD")
  const pendingAlertTo = moment().endOf("week").format("Y-MM-DD")
  const { tipoUsu, searchName } = filter
  const res = await fetch('profiles/v1/list-for-dashboard', token, { page, perPage, pendingAlertFrom, pendingAlertTo, tipoUsu, searchName });
  return { total: res.result.total, data: res.result.data };
}

/**
 * Retorna la lista de alertas y SOS de un dispositivo
 */
export async function getDevicesAlertsCount(profileId: string) {
  const token = await getIdToken();
  return fetch('profiles/v1/devices-alert-count', token, { profileId })
}