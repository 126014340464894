import { stylesheet } from "typestyle";

const style = stylesheet({
  container: {
    width: "100%",
    padding: "2em",
    background: "#FFF",
    boxShadow: "1px 1px 5px 1px rgba(0,0,0,.05)",
    borderRadius: 4,
  },
  rows: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "1em",
    paddingBottom: "1em",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: "10em",
    marginTop: "2em",
    border: 0,
    textAlign: "center",
    fontSize: "1em",
    fontWeight: "bold",
    letterSpacing: "0.1em",
    cursor: "pointer",
    background: "rgba(0, 0, 0, 0.05)",
    padding: "0 1em",
    borderRadius: 4,
    color: "#555",
    height: "2.4em",
  },
});

export default style;
