import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    width: '100%',
    height: '100%',
    padding: '4em 0'
  },
  wrapper: {
    background: '#FFF',
    padding: '2em',
    maxWidth: 500,
    margin: '0 auto',
    boxShadow: '1px 1px 5px 1px rgba(0,0,0,.1)',
    border: '1px solid rgba(0, 0, 0, .09)',
    borderRadius: 4
  },
  header: {
    paddingBottom: '1.5em',
    $nest: {
      '& > span': {
        fontSize: '1.4em',
        fontWeight: 500
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: '1em'
  },
  button: {
    background: 'rgba(0, 0, 0, .04)',
    color: '#555',
    fontWeight: 600,
    padding: '1em',
    borderRadius: 4,
    width: '10em'
  },
  buttonRed: {
    background: 'red',
    color: '#FFF !important',
    fontWeight: 600,
    padding: '1em',
    borderRadius: 4,
    width: '10em'
  },
  message: {
    paddingRight: '2em'
  },
  inputs: {
    padding: '2em 0'
  },
  registerSuccess: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  registerSuccessTitle: {
    fontSize: '2em',
    padding: '1em',
    color: '#666'
  },
  registerSuccessButton: {
    background: 'rgba(0, 0, 0, .04)',
    color: '#555',
    fontWeight: 600,
    padding: '1em',
    borderRadius: 4,
    textAlign: 'center',
    width: '10em',
    margin: '3em'
  }
});

export default style;
