import React, { useState } from 'react'
import InputText from '../Input/InputText';
import styles from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import fetch from '../../utils/fetch';

interface Props {
  token: string;
  afterAdding: () => void;
}

const FormEmpresaAddCliente = ({ token, afterAdding }: Props) => {
  const [ imei, setImei ] = useState("")
  const [ adding, setAdding ] = useState(false)
  const [ errMsg, setErrMsg ] = useState("")

  const addCliente = () => {
    if (!token || !imei) return

    setAdding(true)
    setErrMsg("")

    fetch('empresa/v1/add-cliente', token, { imei }).then(data => {
      setErrMsg("")
      afterAdding()
    }).catch(err => {
      setErrMsg(err.message)
      console.error(err)
    }).finally(() => {
      setAdding(false)
      setImei("")
    })
  }

  return <div className={styles.container}>
    <span className={styles.addSpan}>
      Nuevo usuario:
    </span>
    <InputText
      id="imei"
      value={imei}
      placeholder='IMEI'
      containerClassname={styles.inputContainer}
      onChange={(e) => {
        setImei(e.target.value)
        setErrMsg("")
      }}
      error={errMsg}
    />
    { adding ? 
        <FontAwesomeIcon icon={faSpinner} spin />
      : <button className={styles.addBtn} onClick={addCliente}>Agregar</button>
    }
  </div>
}

export default FormEmpresaAddCliente