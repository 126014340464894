import {stylesheet} from 'typestyle';

const style = stylesheet({
  containerLayout: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  content: {
    width: '100%',
    height: 'calc(100vh - 4em)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'auto',
    scrollBehavior: 'smooth'
  }
});

export default style;
