import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    width: '100%',
    height: '100%',
    padding: '2em',
  },
  optionsContainer: {
    maxWidth: '900px',
    height: '250px',
    margin: '0 auto',
    padding: '1.5em',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.5em',
  },
  option: {
    color: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    boxShadow: '1px 1px 5px 2px rgba(0, 0, 0, 0.08)',
    padding: '1em',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: '0',
    flexShrink: '0',
  },
  icon: {
    fontSize: '3em',
  },
  label: {
    fontSize: '1.5em',
  }
});

export default style;