import crossFetch from 'cross-fetch';
import CustomError from './CustomError';

const serverURL = 'https://api.tagyp.net';

interface ErrorJSON {
  status: string;
  message: string;
  code: string;
}

export default async function fetch(
  context: string,
  token: string | null,
  body?: unknown
) {
  const url = `${serverURL}/${context}`;
  const res = await crossFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : ''
    },
    body: body ? JSON.stringify(body) : undefined
  });

  if (!res.ok) {
    let error: ErrorJSON | null = null;

    try {
      const data = await res.json();
      error = data as ErrorJSON;
    } catch (err) {
      throw new Error('unexpected error');
    }

    if (error.message) {
      console.log(error);
      throw new CustomError(error.message, error.code);
    }
  }

  try {
    const data = await res.json();
    return data;
  } catch (err) {
    throw new Error('unexpected error');
  }
}
