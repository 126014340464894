import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Link, Navigate, useParams} from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux';
import {bindActionCreators} from '@reduxjs/toolkit';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {RootState} from '../../reducers';
import {fetchDevices, setCurrentDevice} from '../../reducers/devices';
import {find, reset, poweroff} from '../../services/command';
import MapLoader from '../../components/MapLoader';
import Layout from '../../components/Layout';
import styles from './styles.module.css';

type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux {}

const connector = connect(
  (state: RootState) => ({
    devices: state.devices,
    auth: state.auth
  }),
  (dispatch) => bindActionCreators({setCurrentDevice, fetchDevices}, dispatch)
);

const Home = (props: Props) => {
    const {auth, devices: devicesReducer} = props;
    const {devices, status} = devicesReducer;
    const { id: current } = useParams();

    const findCb = async () => {
        try {
            if (current) {
                await find(current);
            }
        } catch (error: any) {
            console.log(error);
        }
    };

    const resetCb = async () => {
        try {
            if (current) {
                await reset(current);
            }
        } catch (error: any) {
            console.log(error);
        }
    };

    const poweroffCb = async () => {
        try {
            if (current) {
                await poweroff(current);
            }
        } catch (error) {
            console.log(error);
        }
    };

    if (auth.status === 'loading') {
      return (
        <Layout>
          <div className={styles.container}>
            <div
              style={{
                fontSize: '4em',
                color: 'rgba(0, 0, 0, .3)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1em'
              }}>
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          </div>
        </Layout>
      );
    }

    if (auth.status === 'loaded' && !auth.user) {
      return <Navigate to="/auth/login" replace />;
    }

    if (
      auth.status === 'loaded' &&
      status === 'loaded' &&
      !devices.length
    ) {
      return <Navigate to="/dispositivos/nuevo" replace />;
    }

    const options: {
      label: string;
      icon: IconProp;
      path?: string;
      onClick?: () => void;
    }[] = [
      {
        label: 'Salud',
        icon: ['far', 'heart'],
        path: `/salud/${current}`
      },
      {
        label: 'Ubicaciones',
        icon: ['far', 'search-location'],
        path: `/ubicaciones/${current}`
      },
      {
        label: 'Encontrar',
        icon: ['far', 'bell'],
        onClick: findCb
      },
      {
        label: 'Configuración',
        icon: ['far', 'cog'],
        path: `/configuracion/${current}`
      },
      {
        label: 'Reiniciar',
        icon: ['far', 'redo-alt'],
        onClick: resetCb
      },
      {
        label: 'Apagado',
        icon: ['far', 'power-off'],
        onClick: poweroffCb
      }
    ];

    return (
      <Layout>
        <div className={styles.container}>
          <Link className={styles.mapContainer} to={`/ubicaciones/${current}`}>
            <MapLoader deviceId={current} />
          </Link>

          <div className={styles.optionsContainer}>
            {options.map((e, i) =>
              e.path ? (
                <Link key={e.path + i} className={styles.option} to={e.path}>
                  <FontAwesomeIcon icon={e.icon} />
                  <div>{e.label}</div>
                </Link>
              ) : (
                <button
                  key={i}
                  className={styles.option}
                  onClick={() => e.onClick?.()}>
                  <FontAwesomeIcon icon={e.icon} />
                  <div>{e.label}</div>
                </button>
              )
            )}
          </div>
        </div>
      </Layout>
    );
}

export default connector(Home);
