import {stylesheet} from 'typestyle';

const style = stylesheet({
  container: {
    width: '100%',
    minHeight: '100vh',
    padding: '1.5em 0 2em 0'
  },
  wrapper: {
    marginBottom: '2em',
    background: '#FFF',
    padding: '2em',
    maxWidth: 900,
    margin: '0 auto',
    boxShadow: '1px 1px 5px 1px rgba(0,0,0,.08)',
    borderRadius: 4
  },
  header: {
    paddingBottom: '1.5em',
    $nest: {
      '& > span': {
        fontSize: '1.4em',
        fontWeight: 500
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: '1em'
  },
  button: {
    background: 'rgba(0, 0, 0, .04)',
    color: '#555',
    fontWeight: 600,
    padding: '1em',
    borderRadius: 4,
    width: '10em'
  },
  buttonRed: {
    background: 'red',
    color: '#FFF !important',
    fontWeight: 600,
    padding: '1em',
    borderRadius: 4,
    width: '10em'
  },
  message: {
    paddingRight: '2em'
  },
  sectionHeader: {
    maxWidth: 900,
    margin: '0 auto'
  },
  contact: {
    paddingBottom: '1em'
  },
  contactTitle: {
    padding: '1.5em 0 1em 0'
  },
  contactProperties: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1em'
  }
});

export default style;
