import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import {Device} from '../utils/types';
import {devicesList} from '../services/devices';
import {getCurrentUser} from '../utils/firebase';

type SliceState = {
  status: string;
  current: Device | null;
  devices: Device[];
};

const initialState: SliceState = {
  status: '',
  current: null,
  devices: []
};

export const fetchDevices = createAsyncThunk('devices/list', async () => {
  try {
    const user = getCurrentUser();
    const response = await devicesList(user?.uid as string);
    return response;
  } catch (error) {
    throw error;
  }
});

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    addDevice(state, action: PayloadAction<Device>) {
      state.devices.push(action.payload);
    },
    setCurrentDevice(state, action: PayloadAction<string>) {
      const device = state.devices.find((i) => i.id === action.payload);
      if (device) {
        state.current = device;
      }
    },
    cleanDevices(state, action: PayloadAction) {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDevices.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(fetchDevices.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.devices = action.payload;

      if (!action.payload.find((i) => i.id === state.current?.id)) {
        state.current = action.payload[0] || null;
      }
    });
  }
});

export const {addDevice, cleanDevices, setCurrentDevice} = devicesSlice.actions;

export default devicesSlice.reducer;
